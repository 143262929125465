import { Stack } from "@mui/material";
import React, { useContext } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { WorldTourContext } from "./WorldTourContext";
import { WorldTourTeamCard } from "./WorldTourTeamCard";
import { WorldTourTeamHome } from "./WorldTourTeamHome";
import { ButtonComponent } from "../../../base/button/Button";

export interface LeagueUrlParams {
    teamId: string,
    regionId: string,
    leagueId: string
}
export const WorldTourLeagueHome = () => {
    const leagueUrlParams = useParams<LeagueUrlParams>()
    const worldTourContext = useContext(WorldTourContext)
    const history = useHistory()

    const teams = worldTourContext.getCollection().leagues[`${leagueUrlParams.regionId}/${leagueUrlParams.leagueId}`].teams.map(team => {
        return <WorldTourTeamCard {...team} />
    })
    return <Switch>
        <Route exact path="/teams/:teamId/play/world-tour/:regionId/:leagueId">
            <Stack spacing={2}>
                {teams}
                <ButtonComponent onClick={() => {
                    history.push(`/teams/${leagueUrlParams.teamId}/play/world-tour/${leagueUrlParams.regionId}`)
                    worldTourContext.setLastLeagueId(undefined)
                }
                }>Back to league select</ButtonComponent>
            </Stack>
        </Route>
        <Route path="/teams/:teamId/play/world-tour/:regionId/:leagueId/:opponentId" component={WorldTourTeamHome} />
    </Switch>

}

