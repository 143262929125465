import React, { } from "react";
import './PlayArea.css'
import { PlainSection } from "../../../../base/plainSection/PlainSection";


export interface PlayAreaProps {
  minute: number,
  children: any
}

export const PlayAreaComponent = (props: PlayAreaProps) => {
  return (
    <PlainSection>
      <span className='minute'>{props.minute}:00</span>
      <div className='play-area'>{props.children}</div>

    </PlainSection>
  )
}
