import { Button, Card, CardActions, CardContent, Typography } from "@mui/material"
import React, { useContext } from "react"
import { TeamContext } from "../../TeamContext"
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import { useHistory } from "react-router-dom";
import { NewsCardProps } from "../TeamNews";
export const NewPackNewsCard = (props: NewsCardProps) => {

    const teamContext = useContext(TeamContext)
    const history = useHistory()

    if (teamContext.getUnopenedPacks().length === 0) {
        return <></>
    }

    return props.parent(<Card>
        <CardContent>
            <Typography variant="h5" component="div">
                New Packs <ViewCarouselIcon />
            </Typography>
            <Typography variant="body2">
                {teamContext.getUnopenedPacks().length === 1 ? 'You have a new pack to open' : `You have ${teamContext.getUnopenedPacks().length} new packs to open`}
            </Typography>
        </CardContent>
        <CardActions>
            <Button
                size="small"
                onClick={() => {
                    history.push(`${teamContext.getBaseUrl()}/myteam/packs`)
                }}>

                View now</Button>
        </CardActions>
    </Card>)

}
