

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiceOne, faDiceTwo, faDiceThree, faDiceFour, faDiceFive, faDiceSix } from '@fortawesome/free-solid-svg-icons'
import './dice.css'

interface DiceProps {
    value: number
}

const diceIcons = {
    1: faDiceOne,
    2: faDiceTwo,
    3: faDiceThree,
    4: faDiceFour,
    5: faDiceFive,
    6: faDiceSix,
}

export const Die = (props: DiceProps) => {
    return <FontAwesomeIcon icon={diceIcons[props.value]} size='lg' className={`dice${props.value}`} />
}