import React, { useContext } from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { TeamContext } from "../TeamContext"
import { CompetitionSelect } from "./CompetitionSelect"
import { QuickPlay } from "./QuickPlay"
import { WorldTourHome } from "./worldTour/WorldTourHome"
import { MatchHome } from "./match/MatchHome"

export const TeamPlayComponent = () => {

    const teamContext = useContext(TeamContext)
    const history = useHistory()
    
    if (!window.location.pathname.includes("match") && teamContext.getCurrentMatch()) {
        history.push(`/teams/${teamContext.getTeam().id}/play/match/${teamContext.getCurrentMatch()!.id}`)
    }
    return <><Switch>
        <Route exact path="/teams/:teamId/play" component={CompetitionSelect} />
        <Route path="/teams/:teamId/play/quickplay" component={QuickPlay} />
        <Route path="/teams/:teamId/play/world-tour" component={WorldTourHome} />
        <Route path="/teams/:teamId/play/match/:matchId" component={MatchHome} />
    </Switch></>

}