import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandsClapping, faFutbol, faBottleWater } from '@fortawesome/free-solid-svg-icons'
import '../CardColours.css';
import './StrategyCard.css';
import { StrategyCardState } from '@alienheadwars/football-card-manager-model';

export interface StrategyCardProps {
    strategyCard: StrategyCardState
    selected?: boolean
}

const strategyCardIcons = {
    encourage:faHandsClapping,
    totalFootball: faFutbol,
    waterBreak: faBottleWater
}

export const StrategyCard = (props: StrategyCardProps) => {
    return (
        <div className={`${props.selected ? 'card-selected' : 'strategy-card-border'} strategy-card`}>
            <FontAwesomeIcon icon={strategyCardIcons[props.strategyCard.name]} size='2x'/>
        </div>
    )
}