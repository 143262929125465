import React from "react"
import { ButtonComponent, Type } from "./Button"
import './ChoiceButtons.css'

export interface ChoiceButtonsProps {
    primaryButtonText: string
    primaryButtonAction(): void
    primaryButtonDisabled?: boolean
    primaryButtonWrapper?(button: any): any
    secondardaryButtonText: string
    secondaryButtonAction(): void
    SecondaryButtonDisabled?: boolean
}

const noWrapper = (button: any): any => button

export const ChoiceButtonsComponent = (props: ChoiceButtonsProps) => {

    const primaryButtonWrapper = props.primaryButtonWrapper ?? noWrapper
    return <div className='buttons-container'>
        <ButtonComponent
            type={Type.SECONDARY}
            onClick={props.secondaryButtonAction}
            disabled={props.SecondaryButtonDisabled ?? false}
        >
            {props.secondardaryButtonText}
        </ButtonComponent>
        {primaryButtonWrapper(
            <ButtonComponent
                type={Type.PRIMARY}
                onClick={props.primaryButtonAction}
                disabled={props.primaryButtonDisabled ?? false}
            >
                {props.primaryButtonText}
            </ButtonComponent>
        )}
    </div>
}