import React, { useContext } from "react";
import { getCallableFunction } from "../../../firebase/firebase";
import { ButtonComponent } from "../../base/button/Button";
import { TeamContext } from "../TeamContext";
import { SelectLineup } from "../lineup/SelectLineup";

export const QuickPlay = () => {

    const teamContext = useContext(TeamContext)

    const newMatchFunction = getCallableFunction('createMatch')
    const newMatchAction = () => {
        const params = {
            teamId: teamContext.getTeam().id,
            featureFlags: {
                bonuses: true
            },
            lineup: teamContext.getTeamConfig().lineups.default
        }
        newMatchFunction(params)
    }




    return (
        <>
            <SelectLineup/>
            <ButtonComponent onClick={newMatchAction} loadOnExecute>Let's Play</ButtonComponent>
        </>
    )
}