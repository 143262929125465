import { getAuth } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

import { getFunctions, httpsCallable  } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyDBv7RQPvOV9XtZ4iZdxnahb3jjt83o2-Y",
    authDomain: "football-card-manager.firebaseapp.com",
    projectId: "football-card-manager",
    storageBucket: "football-card-manager.appspot.com",
    messagingSenderId: "944323898008",
    appId: "1:944323898008:web:5c9668ba5db411b87dfeed",
    measurementId: "G-6Q9C4QTNKV"
};

const firebaseApp = initializeApp(firebaseConfig);

export const db =getFirestore(firebaseApp);

export const auth = getAuth();
export const providers = auth.providers


export const functions = getFunctions(firebaseApp,'europe-west2');

const functionPrefix = process.env.NODE_ENV === 'development' ? 'TEST-' :''

export const getCallableFunction = (functionName) => httpsCallable(functions, functionPrefix + functionName)

// if (process.env.NODE_ENV === 'development') {
//     console.log('dev env detected, using emulator')
//     // functions.useEmulator("localhost", 5001)
// }

export const toJson = (object) =>{
    return JSON.parse(JSON.stringify(object))
  }