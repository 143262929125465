import { TeamState } from '@alienheadwars/football-card-manager-model';
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useState } from "react";
import { ButtonComponent } from "../../../../base/button/Button";
import { Overlay } from "../../../../base/overlay/Overlay";
import { PlayerCardComponent } from "../../../../cards/playerCards/PlayerCard";
import { Lineup } from "../../../../team/lineup/Lineup";
import "../Match.css";
export interface ViewOpponentProps {
    team: TeamState
    acknowledge(): void
}

export const ViewOpponent = (props: ViewOpponentProps) => {
    const [viewPlayer, setViewPlayer] = useState(-1)
    const viewPlayerReset = () => { setViewPlayer(-1) }
    return (<>
        <Card>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    You are playing
                </Typography>
                <CardHeader
                    title={<Typography variant="h5" component="div" sx={{ color: props.team.colours?.primaryTextColour ?? 'black' }}>{props.team.name}</Typography>}
                    sx={{
                        backgroundColor: props.team.colours?.primaryBackgroundColour,
                        padding: 2,
                        borderRadius: 2
                    }} />
                <br />
                <Lineup players={props.team.lineup} callback={setViewPlayer}></Lineup>
            </CardContent>
        </Card>
        <ButtonComponent onClick={props.acknowledge}>Let's be 'aving ya!</ButtonComponent>
        {viewPlayer > -1 ? <Overlay onclick={viewPlayerReset}><PlayerCardComponent player={props.team.lineup[viewPlayer]}></PlayerCardComponent></Overlay> : ''}
    </>)



}