import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { getCallableFunction } from "../../../../firebase/firebase";
import { ButtonComponent } from "../../../base/button/Button";
import { TeamContext } from "../../TeamContext";
import { SelectLineup } from "../../lineup/SelectLineup";
import { LeagueUrlParams } from "./WorldTourLeagueHome";

export interface TeamMatchUrlParams extends LeagueUrlParams {
    teamId: string,
    regionId: string,
    leagueId: string,
    opponentId: string
}
export const WorldTourTeamHome = () => {
    const regionUrlParams = useParams<TeamMatchUrlParams>()
    const teamContext = useContext(TeamContext)

    const newMatchFunction = getCallableFunction('createWorldTourMatch')
    const newMatchAction = () => {
        const params = {
            teamId: teamContext.getTeam().id,
            lineup: teamContext.getTeamConfig().lineups.default,
            region: regionUrlParams.regionId,
            league: `${regionUrlParams.regionId}/${regionUrlParams.leagueId}`,
            opponentId: `${regionUrlParams.regionId}/${regionUrlParams.leagueId}/${regionUrlParams.opponentId}`
        }
        newMatchFunction(params)
    }




    return (
        <>
            <SelectLineup />
            <ButtonComponent onClick={newMatchAction} loadOnExecute>Let's Play</ButtonComponent>
        </>
    )
}