import { WorldTourLeague } from '@alienheadwars/football-card-manager-model';
import { Button, CardActions, CardMedia, LinearProgress, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { localise } from '../../../../locale/locale';
import { TeamContext } from "../../TeamContext";
import { WorldTourCard } from './WorldTourCard';
import { WorldTourChallengesCountCard } from './WorldTourChallenges';
import { WorldTourContext } from './WorldTourContext';


export const WorldTourLeagueCard = (league: WorldTourLeague) => {

    const teamContext = useContext(TeamContext)
    const worldTourContext = useContext(WorldTourContext)
    const worldTourProgress = worldTourContext.getWorldTourProgress()
    const itemProgress = worldTourProgress.leagues[league.id]
    const history = useHistory()

    const teamChallengesCompleted = league.teams.reduce((acc, team) => {
        const teamProgress = worldTourProgress.teams[team.id]
        return teamProgress ? acc + teamProgress.challengesComplete.length : acc
    }, 0)

    const teamChallengesTotal = league.teams.reduce((acc, team) => {
        return acc + team.challenges.length
    }, 0)


    return <WorldTourCard
        header={localise(`world-tour.league.${league.id}.name`)}
        subHeader={localise(`world-tour.league.${league.id}.description`)}
        avatar={<CardMedia
            sx={{ height: '15vmin', width: '15vmin', opacity: !itemProgress?.unlocked ? 0.3 : 1 }}
            image={`/images/badges/${league.id}.png`}
            title={localise(`world-tour.league.${league.id}.name`)}
        />}
        id={league.id}
        contentId='leagues'
        defaultExpanded={itemProgress?.unlocked && itemProgress.challengesComplete.length < league.challenges.length}
        disabled={!itemProgress?.unlocked}
    >
        <LinearProgress variant="determinate" value={teamChallengesCompleted / teamChallengesTotal * 100} />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {teamChallengesCompleted}/{teamChallengesTotal} challenges completed
        </Typography>
        <WorldTourChallengesCountCard challenges={league.challenges} itemProgress={itemProgress} />
        <CardActions>
            <Button
                size="small"
                onClick={() => {
                    history.push(`${teamContext.getBaseUrl()}/play/world-tour/${league.id}`)
                    worldTourContext.setLastLeagueId(league.id) 
                    
                }}>

                Play now</Button>
        </CardActions>
    </WorldTourCard>
}

