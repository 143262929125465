import React from "react"
import { AvatarComponentProps } from "../Avatar"

export const ShirtSleeves = (props: AvatarComponentProps) =>{
    return <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <path d="M23.0001 179H167C167 142 160 139 145 129.5L118 114.5C115.5 121.5 102.8 136.5 94.0001 136.5C85.2001 136.5 73.0001 123 72.5001 114.5C69.5001 116 73.1001 116.7 47.5001 129.5C21.9001 142.3 23.0001 161.5 23.0001 179Z" fill={props.colours![1]} />
    <path d="M47.5 129.5L47.5 149L47.5 179H145V153.5V129.5L118 114.5C115.5 121.5 102.8 136.5 94 136.5C85.2 136.5 73 123 72.5 114.5C69.5 116 47.5 129.5 47.5 129.5Z" fill={props.colours![0]} />
</svg>

}
