import { WhereFilterOp, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { db } from "./firebase";


export const getDocument = async (path: string): Promise<any> => {
    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
}


export const listenToDocument = (path: string, callback: (data: any) => void) => {
    const docRef = doc(db, path);
    return onSnapshot(docRef, (doc) => {
        callback(doc.data());
    });
}

export interface queryPart {
    field: string,
    operator: WhereFilterOp,
    value: unknown
}

export const getByQuery = (collectionPath: string, queryParts: queryPart[]): Promise<any[]> => {
    const collectionRef = collection(db, collectionPath);
    const q = query(collectionRef, ...queryParts.map(part => where(part.field, part.operator, part.value)));
    return getDocs(q).then(snapshot => {
        return snapshot.docs.map(doc => doc.data());
    });
}

export const listenToQuery = (collectionPath: string, queryParts: queryPart[], callback: (data: any[]) => void) => {
    const collectionRef = collection(db, collectionPath);
    const q = query(collectionRef, ...queryParts.map(part => where(part.field, part.operator, part.value)));
    return onSnapshot(q, (snapshot) => {
        callback(snapshot.docs.map(doc => doc.data()));
    });
}

export const putDocument = (path: string, data: any) => {
    const docRef = doc(db, path);
    return setDoc(docRef, data, { merge: false });
}

export const mergeDocument = (path: string, data: any) => {
    const docRef = doc(db, path);
    return setDoc(docRef, data, { merge: true });
}

export const deleteDocument = (path: string) => {
    const docRef = doc(db, path);
    return deleteDoc(docRef);
}