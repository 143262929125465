
import { faClipboardQuestion, faDoorOpen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { ButtonComponent, Type } from '../base/button/Button';
import { PageContainerComponent } from '../base/pageContainer/PageContainer';
import { PlainSection } from "../base/plainSection/PlainSection";
import { TeamUrlParams } from "../team/TeamContext";
import "./Options.css";
import { ResetTutorials } from "./resetTutorials/ResetTutorials";
import { ResignFromTeam } from "./resignFromTeam/ResignFromTeam";
import { SignOut } from "./signOut/SignOut";
import { ResetWorldTour } from './resetWorldTour/ResetWorldTour';
import { UnlockAllWorldTour } from './unlockAllWorldTour/unlockAllWorldTour';

const OptionsList = (props: any) => {
    const teamId = useParams<TeamUrlParams>().teamId
    const history = useHistory()
    return <PageContainerComponent>
        <PlainSection dontGrow={true}>
            <ButtonComponent onClick={() => {
                history.push(`/teams/${teamId}/options/resetTutorials`)
            }}>Reset tutorials <FontAwesomeIcon icon={faClipboardQuestion} /></ButtonComponent>
            <ButtonComponent onClick={() => {
                history.push(`/teams/${teamId}/options/resignFromTeam`)
            }}>Resign from team <FontAwesomeIcon icon={faTrash} /></ButtonComponent>
            <ButtonComponent onClick={() => {
                history.push(`/teams/${teamId}/options/resetWorldTour`)
            }}>Reset World Tour <FontAwesomeIcon icon={faTrash} /></ButtonComponent>
            <ButtonComponent onClick={() => {
                history.push(`/teams/${teamId}/options/unlockAllWorldTour`)
            }}>Unlock All World Tour <FontAwesomeIcon icon={faTrash} /></ButtonComponent>
            <ButtonComponent onClick={() => {
                history.push(`/teams/${teamId}/options/signOut`)
            }}>Sign out <FontAwesomeIcon icon={faDoorOpen} /></ButtonComponent>
            <ButtonComponent type={Type.SECONDARY} onClick={() => {
                history.goBack()
            }}>Exit options</ButtonComponent>
        </PlainSection>
    </PageContainerComponent>
}

export const Options = (props: any) => {

    return <Switch>
        <Route exact path="/teams/:teamId/options" component={OptionsList} />
        <Route path="/teams/:teamId/options/resignFromTeam" component={ResignFromTeam} />
        <Route path="/teams/:teamId/options/resetTutorials" component={ResetTutorials} />
        <Route path="/teams/:teamId/options/resetWorldTour" component={ResetWorldTour} />
        <Route path="/teams/:teamId/options/unlockAllWorldTour" component={UnlockAllWorldTour} />
        <Route path="/teams/:teamId/options/signOut" component={SignOut} />
    </Switch>
}