import { V2Pack } from "@alienheadwars/football-card-manager-model"
import { Button, Card, CardActions, CardHeader, CardMedia, Stack } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { localise, localiseAndReplace } from "../../../../locale/locale"
import { HelpOverlay } from "../../../base/tutorial/helpOverlay/HelpOverlay"
import { TeamContext } from "../../TeamContext"

export const NewPackListPage = () => {

    const teamContext = useContext(TeamContext)
    const history = useHistory()

    useEffect(() => {
        if (teamContext.getUnopenedPacks().length === 0
        ) {
            history.goBack()
        }
    }, [teamContext, history]
    )

    return <Stack spacing={2}>{
        teamContext.getUnopenedPacks().map((pack) => {
            return <HelpOverlay tutorialId='newPack' helpText={localise('tutorial.newPack')} blockSkip={true}>
                <NewPackCard key={pack.uid} pack={pack} openFunction={() => {
                    history.push(`${teamContext.getBaseUrl()}/myteam/packs/${pack.uid}`)
                }} />
            </HelpOverlay>
        })
    }</Stack>
}

const NewPackCard = (props: { pack: V2Pack, openFunction: () => void }) => {
    return <Card>
        <CardHeader
            avatar={
                <CardMedia
                    component="img"
                    sx={{ maxWidth: 60 }}
                    image={`/images/${props.pack.art[0].image}.png`}
                    alt={props.pack.art[0].image}
                />
            }
            title={localiseAndReplace({ id: `pack.${props.pack.id}.name`, replacements: props.pack.params })}
            subheader={localiseAndReplace({ id: `pack.${props.pack.id}.description`, replacements: props.pack.params })}
        />
        <CardActions>
            <Button
                size="small"
                onClick={props.openFunction}
            >
                Open now</Button>
        </CardActions>

    </Card>
}