import { Player } from '@alienheadwars/football-card-manager-model';
import React from "react";
import { MiniPlayerCard } from "../../cards/playerCards/MiniPlayerCard";
import "./Lineup.css";

interface LineupProps {
    players: Player[],
    callback(index: number): void
}

export const Lineup = (props: LineupProps) => {

    const player = (shiftedIndex: number) => {
        //We use a shifted index in this component to make it easier to visualise in footy terms, e.g. 1 = GK, 2=RB, 11=LW etc.
        const actualIndex = shiftedIndex - 1
        const player = props.players[actualIndex]
        if (player) {
            return (<div className='lineup-player'>
                <MiniPlayerCard player={player} onClick={() => props.callback(actualIndex)} />
            </div>)
        }
        else return '?'
    }
    return (
        <div className='lineup-container'>
            <div className='lineup-row'>
                {player(1)}
            </div>
            <div className='lineup-row'>
                {player(2)}{player(5)}{player(4)}{player(3)}
            </div>
            <div className='lineup-row'>
                {player(7)}{player(8)}{player(6)}{player(11)}
            </div>
            <div className='lineup-row'>
                {player(10)}{player(9)}
            </div>
        </div>
    )
}
