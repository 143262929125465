import React from "react"
import { NewPackSnack } from "./home/newpack/NewPackSnack"



export const TeamSnack = () => {
    //I've stripped this class out, it was originally managing complex state to ensure that it was only showing one snack at a time,
    // However it needed major refactoring, and it wasn't needed for simplicity of current use cases
    //In the future I think we need an abstract snack component that automatically registers with a snack context and has controls over wether it shows
    return <NewPackSnack />

}