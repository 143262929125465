"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Abilities = void 0;
var Abilities;
(function (Abilities) {
    Abilities["VERSATILE"] = "versatile";
    Abilities["DETERMINED"] = "determined";
    Abilities["QUICK_OFF_THE_MARK"] = "quick_off_the_mark";
    Abilities["INSPIRING_PLAY"] = "inspiring_play";
    Abilities["MAN_MARKER"] = "man_marker";
    Abilities["HARD_TACKLER"] = "hard_tackler";
    Abilities["TEAMWORK"] = "teamwork";
    Abilities["STRONG"] = "strong";
    Abilities["PACE_ABUSE"] = "pace_abuse";
    Abilities["POACHER"] = "poacher";
    Abilities["BRICK_WALL"] = "brick_wall";
    Abilities["ROCKET_SHOT"] = "rocket_shot";
    Abilities["FREE_KICK_SPECIALIST"] = "free_kick_specialist";
    Abilities["MOMENTS"] = "moments";
    Abilities["UNDERDOG"] = "underdog";
    Abilities["SUPER_SUB"] = "super_sub";
    Abilities["KILLER_BALL"] = "killer_ball";
    Abilities["PLAYMAKER"] = "playmaker";
    Abilities["ENGINE"] = "engine";
    Abilities["ANTICIPATION"] = "anticipation";
    Abilities["WING_MAN"] = "wing_man";
    Abilities["IRON_MAN"] = "iron_man";
    Abilities["SILKY"] = "silky";
    Abilities["CAT"] = "cat";
    Abilities["LONG_ARMS"] = "long_arms";
    Abilities["STEADY"] = "steady";
    Abilities["POWER_HEADER"] = "power_header";
    Abilities["HEART_IN_SLEEVE"] = "heart_in_sleeve";
    Abilities["PERFECTIONIST"] = "perfectionist";
    Abilities["PASSING_MASTER"] = "passing_master";
    Abilities["TRICKSTER"] = "trickster";
    Abilities["TERRIER"] = "terrier";
    Abilities["RELIABLE"] = "reliable";
    Abilities["TALENTED"] = "talented";
    Abilities["DISTRACTION"] = "distraction";
    Abilities["PRESSING_FORWARD"] = "pressing_forward";
    Abilities["WORK_HORSE"] = "work_horse";
    Abilities["ONE_MAN_SHOW"] = "one_man_show";
    Abilities["FIGHTER"] = "fighter";
    Abilities["FINISHER"] = "finisher";
    Abilities["CAPTAIN"] = "captain";
    Abilities["VISION"] = "vision";
    Abilities["DRIBBLER"] = "dribbler";
    Abilities["FLAIR"] = "flair";
    Abilities["FUTURE_STAR"] = "future_star";
    Abilities["FALLEN_STAR"] = "fallen_star";
})(Abilities || (exports.Abilities = Abilities = {}));
