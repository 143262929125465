import React, { useEffect, useState } from "react"
import { auth, toJson } from "../../../../firebase/firebase"
import { deleteDocument, getDocument, mergeDocument } from "../../../../firebase/firestore"

export const TutorialContext = React.createContext({
    completeTutorial: (value: string) => { },
    currentTutorial: null as unknown as String,
    addTutorial: (value: string) => { },
    removeTutorial: (value: string) => { },
    resetTutorials: () => { }
})

export const TutorialTracker = (props) => {

    const [tutorialState, setTutorialState] = useState({
        tutorialsViewed: {} as any,
        waitingTutorials: [] as String[],
        current: null as unknown as String
    })

    const getTutorialTracker = async () => {
        const tutorialTracker = getDocument(`tutorialTrackers/${auth.currentUser!.uid}`)
        if (tutorialTracker) {
            return tutorialTracker
        } else {
            return {}
        }
    }
    const updateTutorialTracker = (tutorialId: string) => {
        const updateDoc: any = {}
        updateDoc[tutorialId] = true
        mergeDocument(`tutorialTrackers/${auth.currentUser!.uid}`, toJson(updateDoc))
    }
    const resetTutorialTracker = () => {
        deleteDocument(`tutorialTrackers/${auth.currentUser!.uid}`)
    }

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            if (user) {
                getTutorialTracker().then(response => {
                    setTutorialState(existingState => {
                        const newTutorialsViewed = { ...existingState.tutorialsViewed, ...response }
                        const newWaitingTutorials = existingState.waitingTutorials.filter(item => !newTutorialsViewed[item.toString()])
                        const newCurrentTutorial = newWaitingTutorials.length === 0 ? null: newWaitingTutorials[0]
                        return {
                            tutorialsViewed: newTutorialsViewed,
                            waitingTutorials: newWaitingTutorials,
                            current: newCurrentTutorial!
                        }
                    })
                })
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, [])

    return <TutorialContext.Provider value={{
        completeTutorial: tutorialId => {
            setTutorialState(existingState => {
                const newTutorialsViewed = { ...existingState.tutorialsViewed }
                newTutorialsViewed[tutorialId] = true
                const newWaitingTutorials = existingState.waitingTutorials.filter(item => item !== tutorialId)
                const newCurrentTutorial = newWaitingTutorials.length === 0 ? null : newWaitingTutorials[0]
                return {
                    tutorialsViewed: newTutorialsViewed,
                    waitingTutorials: newWaitingTutorials,
                    current: newCurrentTutorial!
                }
            })
            updateTutorialTracker(tutorialId)
        },
        removeTutorial: tutorialId => {
            setTutorialState(existingState => {
                const newWaitingTutorials = existingState.waitingTutorials.filter(item => item !== tutorialId)
                const newCurrentTutorial = newWaitingTutorials.length === 0 ? null : newWaitingTutorials[0]
                return {
                    tutorialsViewed: existingState.tutorialsViewed,
                    waitingTutorials: newWaitingTutorials,
                    current: newCurrentTutorial!
                }
            })
            updateTutorialTracker(tutorialId)
        },
        currentTutorial: tutorialState.current,
        addTutorial: tutorialId => {

            if (tutorialState.tutorialsViewed[tutorialId] || tutorialState.waitingTutorials.includes(tutorialId)) {
                return
            }
            setTutorialState(existingState => {
                const newWaitingTutorials = [...existingState.waitingTutorials]
                newWaitingTutorials.push(tutorialId)
                const newCurrentTutorial = newWaitingTutorials.length === 0 ? null : newWaitingTutorials[0]
                return {
                    tutorialsViewed: tutorialState.tutorialsViewed,
                    waitingTutorials: newWaitingTutorials,
                    current: newCurrentTutorial!
                }
            })
        },
        resetTutorials: () => {
            setTutorialState(existingState => {
                const newState = { ...existingState }
                newState.tutorialsViewed = {}
                return newState

            })
            resetTutorialTracker()
        }
    }}>{props.children}</TutorialContext.Provider>
}