import { MatchState } from '@alienheadwars/football-card-manager-model';
import React, { useEffect, useState } from "react";
import { getCallableFunction } from "../../../../firebase/firebase";
import { ButtonComponent } from "../../../base/button/Button";
import { ChoiceButtonsComponent } from "../../../base/button/ChoiceButtons";
import { PageContainerComponent } from "../../../base/pageContainer/PageContainer";
import { HelpOverlay } from "../../../base/tutorial/helpOverlay/HelpOverlay";
import './Match.css';
import { ScoreboardComponent } from "./Scoreboard";
import { CommentaryComponent } from "./commentary/Commentary";
import { PlayAreaComponent } from "./play-area/PlayArea";
import { PlayRound } from "./round/PlayRound";
import { ViewOpponent } from "./viewOpponenent/ViewOpponent";


export interface HasMatchState {
    matchState: MatchState
}

export interface MatchStateProps extends HasMatchState {
    onComplete(): void
}

export const MatchStateComponent = (props: MatchStateProps) => {
    const matchState = props.matchState
    const [acknowledged, setAcknowledged] = useState(false)
    const [skip, setSkip] = useState(false)
    const [play, setPlay] = useState(0)

    const [latestSkipped, setLatestSkipped] = useState(0)
    const currentRoundNumber = props.matchState.rounds.length
    useEffect(() => {
        const matchActionFunction = getCallableFunction('actionMatch')
        const matchAction = () => {
            matchActionFunction({
                matchId: props.matchState.id,
            })
        }
        if (skip && !props.matchState.matchFinished && currentRoundNumber > latestSkipped) {
            setLatestSkipped(currentRoundNumber)
            matchAction()
        }
    },[skip, props.matchState.matchFinished, currentRoundNumber, latestSkipped, props.matchState.id])
    if (!acknowledged && currentRoundNumber<2) {
        return <ViewOpponent acknowledge={() => setAcknowledged(true)} team={matchState.teams[matchState.awayTeam]} />
    }


    if (!(play === 0)) {
        return (
            <PageContainerComponent>
                <ScoreboardComponent matchState={props.matchState} />
                <PlayRound matchState={props.matchState} finishRoundCallback={() => { setPlay(0) }} roundNumber={play} />
            </PageContainerComponent>
        )
    }
    const minute = props.matchState.matchFinished ? 90 : props.matchState.rounds[props.matchState.rounds.length - 1].minute
    return (
        <PageContainerComponent>
            <ScoreboardComponent matchState={props.matchState} />
            <PlayAreaComponent minute={minute}><CommentaryComponent matchState={props.matchState} /></PlayAreaComponent>
            {
                matchState.matchFinished ?
                    <ButtonComponent onClick={props.onComplete}>Finish Game</ButtonComponent> :
                    <ChoiceButtonsComponent
                        primaryButtonText='Next Round'
                        primaryButtonAction={() => { setPlay(currentRoundNumber) }}
                        primaryButtonDisabled={skip || latestSkipped === currentRoundNumber}
                        primaryButtonWrapper={(button) => <HelpOverlay helpText="Click 'next round' to play a round. 'Skip' will simulate the rest of the match" tutorialId='roundNextRound'>{button}</HelpOverlay>}
                        secondardaryButtonText={skip ? 'Cancel Skip' : 'Skip Rounds'}
                        secondaryButtonAction={() => { setSkip(!skip) }}
                    />
            }
        </PageContainerComponent>
    )
}