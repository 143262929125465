import { Commentary, MatchState } from "@alienheadwars/football-card-manager-model";
import React, { useState } from "react";
import { Overlay } from "../../../../base/overlay/Overlay";
import { HelpOverlay } from "../../../../base/tutorial/helpOverlay/HelpOverlay";
import { HasMatchState } from "../MatchStateViewer";
import { RoundSummary } from "../round/RoundSummary";
import "./Commentary.css";
export const CommentaryComponent = (props: HasMatchState) => {
    const [showMinute, setShowMinute] = useState(-1)
    const matchState = props.matchState
    const commentary = [...matchState.commentary].reverse().map((line) => <CommentaryLineComponent line={line} matchState={matchState} setShowMinute={setShowMinute} />)
    if (commentary.length >= 2) {
        commentary[0] = (<HelpOverlay helpText="Here is how you got on" tutorialId='commentary' backgroundColour='white'>{commentary[0]}</HelpOverlay>)
    }

    return <>
        {showMinute >= 0 ?
            <Overlay onclick={() => { setShowMinute(-1) }}>
                <RoundSummary roundState={props.matchState.rounds.find(round => { return round.minute === showMinute })} matchState={props.matchState} />
            </Overlay> : ''}
        <ul className="commentary-container">
            {commentary}
        </ul> </>
}


const CommentaryLineComponent = (props: { line: Commentary, matchState: MatchState, setShowMinute: (minute: number) => void }) => {
    const { line, matchState, setShowMinute } = props
    let highlighted = false
    const entryBody = line.body.map(bodyLine => {
        const parts = line.highlight ? bodyLine.split(new RegExp(`(${line.highlight})`, 'gi')) : [bodyLine]
        const partsHighlighted = parts.map((part) => {
            const highlight: boolean = !(highlighted) && line.highlight && part.toLowerCase() === line.highlight.toLowerCase()
            if (highlight) {
                highlighted = true
            }
            const className = highlight ? 'commentary-entry-highlight' : ''
            return <span className={className}>
                {part}
            </span>
        })
        return <p className='commentary-entry-body'>{partsHighlighted}</p>
    })
    return <li className={`commentary-entry commentary-entry-${line.type.toLowerCase()}`} >
        {line.minute ? <span className='commentary-entry-time'>{line.minute} mins</span> : ''}
        <header className='commentary-entry-title'>{line.title}</header>
        {entryBody}
        {line.detail ? <span className='round-link' onClick={() => { setShowMinute(line.minute) }}>{line.detail}</span> : ''}
        <ProgressBarComponent line={line} matchState={matchState} />
    </li >
}

//This is getting very hacky now. really I think commentary should be done by the front end, with the backend generating a randomness seed each round
// That way it can be language tokened and the matchstate model would be simpler
const ProgressBarComponent = (props: { line: Commentary, matchState: MatchState }) => {
    const { line, matchState } = props
    if (!line.detail) {
        return <></>
    }
    const round = matchState.rounds.find(round => { return round.minute === line.minute })!
    const attackScore = round.attackingScore!
    const attackColour = matchState.teams[round.attackingTeam].colours?.primaryBackgroundColour ?? 'salmon'
    const defendScore = round.defendingScore!
    const defendColour = matchState.teams[round.defendingTeam].colours?.primaryBackgroundColour ?? 'salmon'

    const attackWidth = defendScore <= attackScore ? 100 : (attackScore / defendScore) * 100
    const defendWidth = defendScore <= attackScore ? 0 : (1 - (attackScore / defendScore)) * 100
    return <div style={{width:'100%'}}>
        <span style={{
            display: 'inline-block',
            height: '0.5vmin',
            width: `${attackWidth}%`,
            backgroundColor: attackColour
        }}
        />
        <span style={{
            display: 'inline-block',
            height: '0.5vmin',
            width: `${defendWidth}%`,
            backgroundColor: defendColour
        }}
        />
        </div>

}
