import "./TextInput.css";
interface textCallback {
  (input: string): void
}

export interface TextInputProps {
  children?: any,
  placeholder: string,
  value: string,
  onChange: textCallback
}

export const TextInput = (props: TextInputProps) => {
  return (
    <input
      type='text'
      className='textInput'
      placeholder={props.placeholder}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}>{props.children}</input>
  );
}