import { Player } from '@alienheadwars/football-card-manager-model';
import { Card, CardActionArea, Grid, Typography } from '@mui/material';
import React from 'react';
import { localise } from '../../../locale/locale';
import { colours } from '../../base/styles/Colours';
import '../CardColours.css';
import './PlayerCard.css';
import { Avatar, AvatarComponentProps } from './avatarComponents/Avatar';
export interface PlayerCardProps<PlayerType extends Player> {
    player: PlayerType
    onClick?(): void
    actions?: React.ReactNode
}

const doNothing = () => { }

export const PlayerCardComponent:
    <PlayerType extends Player>
        (props: PlayerCardProps<PlayerType>) => any
    = (props) => {

        const avatarComponentsProps: AvatarComponentProps[] = [...props.player.avatar ?? []]
        if (avatarComponentsProps.length === 0) {
            avatarComponentsProps.push({
                type: "image",
                componentName: 'Player 2'
            })
        }
        const avatarComponents = avatarComponentsProps.map(props => {
            return <Avatar {...props} className='full-card-avatar' />
        })

        const playerName = <div style={{
            position: 'absolute',
            top: '0vh',
            zIndex: 102,
            height: '6vh',
            background: 'rgba(68, 68, 68, 0.5)',
            width: '36vh',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            // fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '3.5vh',
            lineHeight: '3.8vh',
        }}>
            {props.player.name}
        </div>

        const playerAttack = (
            <div style={{
                position: 'absolute',
                top: '0vh',
                left: '-3vh',
                zIndex: 102,
                height: '6vh',
                width: '6vh',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#FFFFFF',
                fontWeight: '400',
                fontSize: '3.5vh',
                lineHeight: '3.8vh',
                backgroundColor: '#00CC2D',
                borderRadius: '50%',
            }}>
                <span style={{
                    zIndex: 103,
                    // textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                }}>
                    {props.player.attack}
                </span>
            </div>
        );

        const playerDefense = (
            <div style={{
                position: 'absolute',
                top: '0vh',
                right: '-3vh',
                zIndex: 102,
                height: '6vh',
                width: '6vh',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#FFFFFF',
                fontWeight: '400',
                fontSize: '3.5vh',
                lineHeight: '3.8vh',
                backgroundColor: '#EF0000',
                borderRadius: '50%',
            }}>
                <span style={{
                    zIndex: 103,
                }}>
                    {props.player.defense}
                </span>
            </div>
        );


        const playerAbilities = props.player.abilities?.map((ability) => {
            return <Grid item xs={12} >
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Typography variant="h6" component="div" style={{ fontSize: 'x-small' }}>
                        {localise(`ability-${ability.id}-name`)}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: 'xx-small' }}>
                        {localise(`ability-${ability.id}-description`)}
                    </Typography></Card></Grid>
        })

        const cardOutline = <div style={{
            position: 'absolute',
            left: '1vh',
            top: '1vh',
            width: 'calc(100% - 3vh)',
            height: 'calc(100% - 3vh)',
            border: `0.5vh solid ${colours[`${props.player.level.toLowerCase()}-border`]}`,
            borderRadius: '6vh 6vh 6vh 6vh',
            zIndex: 101
        }} />
        return <>
            <Card sx={{
                width: '36vh',
                backgroundColor: colours[`${props.player.level.toLowerCase()}-background`],
                borderRadius: '6vh 6vh 6vh 6vh',
                overflow: 'visible', // This will allow the child components to overflow
            }}>
                <CardActionArea onClick={props.onClick ?? doNothing}>

                    <div className='full-card-avatar-container' style={{
                        overflow: 'hidden',
                        borderRadius: '6vh 6vh 0vh 0vh',
                    }}>
                        {avatarComponents}
                    </div>

                    <div className='player-info' style={{
                        position: 'absolute',
                        top: '30vh', width: '36vh'
                    }}>
                        {playerName}
                        {playerAttack}
                        {playerDefense}
                    </div>

                    <Grid container spacing={2} style={{
                        paddingLeft: '4vh',
                        paddingRight: '4vh',
                        paddingTop: '1vh',
                        paddingBottom: '4vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        {playerAbilities}
                        {props.player.leagueId ? <Grid item xs={4}>
                            <img
                                src={`/images/badges/${props.player.leagueId}.png`}
                                alt="playerpic"
                                style={{ border: `0.1vh solid ${colours[`${props.player.level.toLowerCase()}-border`]}`, width: '100%' }}
                            />
                        </Grid> : ''}
                        {props.player.teamId ? <Grid item xs={4}>
                            <img
                                src={`/images/badges/${props.player.teamId}.png`}
                                alt="playerpic"
                                style={{ border: `0.1vh solid ${colours[`${props.player.level.toLowerCase()}-border`]}`, width: '100%' }}
                            />
                        </Grid> : ''
                        }
                        {props.player.nationId ? <Grid item xs={4} >
                            <img
                                src={`/images/flags/192x144/${props.player.nationId}.png`}
                                alt="playerpic"
                                style={{ width: '100%', }}
                            />
                        </Grid> : ''}
                    </Grid>

                    {cardOutline}
                </CardActionArea>
            </Card>
            {props.actions}
        </>
    }
