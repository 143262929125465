import { Button, Card, CardActions, CardHeader, CardMedia, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { TeamContext } from "../TeamContext";

export const CompetitionSelect = () => {

    const teamContext = useContext(TeamContext)
    const history = useHistory()

    const worldTour = <Card>
        <CardHeader
            avatar={<CardMedia
                component="img"
                sx={{ width: 120 }}
                image={'/images/world-tour/world-tour.png'}
                alt={'world-tour'}
            />}
            title={<Typography variant="h5" component="div">World tour</Typography>}
            subheader={<Typography variant="body2" color="text.secondary">
                Conquer the globe as you beat league after league to earn packs.
            </Typography>}
        />
        <CardActions>
            <Button
                size="small"
                onClick={() => {
                    history.push(`${teamContext.getBaseUrl()}/play/world-tour/resume`)
                }}>

                Play now</Button>
        </CardActions>
    </Card>

    const quickPlay = <Card>
    <CardHeader
        avatar={<CardMedia
            component="img"
            sx={{ width: 120 }}
            image={'/images/quick-play/quick-play.png'}
            alt={'world-tour'}
        />}
        title={<Typography variant="h5" component="div">Quickplay</Typography>}
        subheader={<Typography variant="body2" color="text.secondary">
            Challenge a random team to a "friendly".
        </Typography>}
    />
    <CardActions>
        <Button
            size="small"
            onClick={() => {
                history.push(`${teamContext.getBaseUrl()}/play/world-tour`)
            }}>

            Play now</Button>
    </CardActions>
</Card>

    return (<Grid container spacing={2}>
        <Grid item>{worldTour}</Grid>
        <Grid item>{quickPlay}</Grid>
    </Grid>)
}

