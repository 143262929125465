import { MatchState } from "@alienheadwars/football-card-manager-model"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { listenToDocument } from "../../../../firebase/firestore"
import { MatchStateComponent } from "./MatchStateViewer"

export interface MatchUrlParams {
    teamId: string,
    matchId: string,
}

//TODO very dirty, need to refactor
 interface WorldTourMatchState extends MatchState {
    matchMeta: {
        region: string,
        league: string,
        opponentId: string
    },
    callbacks: string[]
}

export const MatchHome = () => {
    const regionUrlParams = useParams<MatchUrlParams>()
    const [matchState, setMatchState] = useState(undefined as unknown as WorldTourMatchState)

    const history = useHistory()

    useEffect(() => {
        listenToDocument(`matches/${regionUrlParams.matchId}`, setMatchState)
    }, [regionUrlParams.matchId])
    
    if (!matchState) {
        return <></>
    }

    const onComplete=()=>{
        history.push(`/teams/${regionUrlParams.teamId}/play/world-tour/${matchState.matchMeta.league}`)
    }
    return <MatchStateComponent matchState={matchState} onComplete={onComplete}/>
}