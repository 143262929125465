import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from "react";
import './Button.css';

export enum Type {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export interface ButtonComponentProps {
  children: any,
  onClick(): void
  type?: Type
  disabled?: boolean
  loading?: boolean
  loadOnExecute?: boolean,
  size?: string
}

export const ButtonComponent = (props: ButtonComponentProps) => {
  const [loading, setLoading] = useState(false)
  return <LoadingButton
    variant="contained"
    loading={props.loading ?? loading ?? false}
    disabled={props.disabled ?? false}
    color={props.type??Type.PRIMARY}
    onClick={() => {
      props.onClick()
      if (props.loadOnExecute) {
        setLoading(true)
      }
    }

    }
  >
    {props.children}
  </LoadingButton >
}