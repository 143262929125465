
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ChoiceButtonsComponent } from "../../base/button/ChoiceButtons";
import { MainBodyTextComponent } from "../../base/mainBodyText/MainBodyText";
import { PageContainerComponent } from "../../base/pageContainer/PageContainer";
import { TutorialContext } from "../../base/tutorial/tutorialTracker/TutorialTracker";
export const ResetTutorials = (props: any) => {
    const history = useHistory()
    const tutorialContext = useContext(TutorialContext)
    return <PageContainerComponent long={true}>
        <MainBodyTextComponent>Are you sure you want to reset all tutorials?
        </MainBodyTextComponent>
        <ChoiceButtonsComponent
            primaryButtonText='reset tutorials'
            primaryButtonAction={() => {
                tutorialContext.resetTutorials()
                history.push('/teams')
            }
            }
            secondardaryButtonText='cancel'
            secondaryButtonAction={() => history.goBack()}
        />
    </PageContainerComponent>
}