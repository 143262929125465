export const colours = {
    PRIMARY: '#883CA3',

    'copper-background':'#fbe6dc',
    'bronze-background':'#fff1d7',
    'silver-background':'#f3f8ff',
    'gold-background':'#fffae3',
    'platinum-background':'#fcfcfc',
    'diamond-background':'#f3fbfe',
    'copper-border': '#1a7ba8',
    'bronze-border': '#5d6418',
    'silver-border': '#9FADBF',
    'gold-border': '#FFF0A5',
    'platinum-border': '#F4F4F4',
    'diamond-border': '#D7F1FD',
    'card-selected': '#09F367',
    'card-selected-border': '#09F367'
}

// .copper {
    // background: #F3AD8F;
// }

// .bronze {
//     background: #FFD37E;
// }

// .silver {
//     background: #d7e8ff;
// }

// .gold {
//     background: #FFF0A5;
// }

// .platinum {
//     background: #F4F4F4;
// }

// .diamond {
//     background: rgb(215, 241, 253, 0.5);
// }

// .copper-border {
//     border-color: #F3AD8F;
// }

// .bronze-border {
//     border-color: #FFD37E;
// }

// .silver-border {
//     border-color: #9FADBF;
// }

// .gold-border {
//     border-color: #FFF0A5;
// }

// .platinum-border {
//     border-color: #F4F4F4;
// }

// .diamond-border {
//     border-color: #D7F1FD;
// }

// .card-selected {
//     border: 2px solid #09F367;
// }