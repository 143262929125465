
import { useHistory } from "react-router-dom";
import { getCallableFunction } from "../../../firebase/firebase";
import { ChoiceButtonsComponent } from "../../base/button/ChoiceButtons";
import { MainBodyTextComponent } from "../../base/mainBodyText/MainBodyText";
import { PageContainerComponent } from "../../base/pageContainer/PageContainer";
import React from "react";
export const ResignFromTeam = (props: any) => {
    const history = useHistory()
    const resignFromTeam = getCallableFunction('v1ResignFromTeam')
    return <PageContainerComponent long={true}>
        <MainBodyTextComponent>Are you sure you want to resign from the team? You will no longer control your current team and will be prompted to create a new one.
            The only way to undo this is to beg Alun to reinstate you.
        </MainBodyTextComponent>
        <ChoiceButtonsComponent
            primaryButtonText='resign'
            primaryButtonAction={() => {
                resignFromTeam().then(() => {
                    history.push('/teams')
                })
            }
            }
            secondardaryButtonText='cancel'
            secondaryButtonAction={() => history.goBack()}
        />
    </PageContainerComponent>
}