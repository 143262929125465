"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Level = void 0;
var Level;
(function (Level) {
    Level["COPPER"] = "COPPER";
    Level["BRONZE"] = "BRONZE";
    Level["SILVER"] = "SILVER";
    Level["GOLD"] = "GOLD";
    Level["PLATINUM"] = "PLATINUM";
    Level["DIAMOND"] = "DIAMOND";
})(Level || (exports.Level = Level = {}));
