"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackSizes = void 0;
exports.PackSizes = {
    SINGLE: {
        atLevel: 1,
        atLevelBelow: 0,
    },
    SMALL: {
        atLevel: 1,
        atLevelBelow: 2
    },
    BUMPER: {
        atLevel: 1,
        atLevelBelow: 4,
    },
};
