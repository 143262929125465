import './PlainSection.css';


export interface PlainSectionProps {
  dontGrow?: boolean
  children: any
}

export const PlainSection = (props: PlainSectionProps) => {
  return (
    <div className="plain-section" style={{
      flexGrow: props.dontGrow ? 0 : 1
    }}>
      {props.children}
    </div>
  )
}
