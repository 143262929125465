import React from "react"
import { useContext, useEffect } from "react"
import { HelpTextComponent } from "../../helpText/HelpTextComponent"
import { Overlay } from "../../overlay/Overlay"
import { TutorialContext } from "../tutorialTracker/TutorialTracker"
import './HelpOverlay.css'

export interface HelpOverlayProps {
    children: any
    helpText: any
    textHeight?: string
    backgroundColour?: string
    tutorialId: string
    additionalCondition?: boolean
    alternatePostiveChildren?: any
    blockSkip?: boolean
}

export const HelpOverlay = (props: HelpOverlayProps) => {

    const tutorialContext = useContext(TutorialContext)


    const helpTextStyle = {
        top: props.textHeight ?? '50vh'
    }

    const helpText = <div className='helpTextContainer' style={helpTextStyle}>
        <HelpTextComponent children={props.helpText} />
    </div>

    const childElementContainerStyle = {

        backgroundColor: props.backgroundColour ?? 'unset'
    }
    useEffect(() => {
        if (props.additionalCondition ?? true) {
            tutorialContext.addTutorial(props.tutorialId)
            return function cleanup() {
                tutorialContext.removeTutorial(props.tutorialId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.additionalCondition])

    useEffect(() => {
        if (tutorialContext.currentTutorial === props.tutorialId) {
            return function cleanup() {
                tutorialContext.completeTutorial(props.tutorialId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tutorialContext.currentTutorial, props.tutorialId])


    if (!(props.additionalCondition ?? true) || !(tutorialContext.currentTutorial === props.tutorialId)) {

        return props.children
    }

    return <div className='helpContainer' onClick={() => {
        if (props.tutorialId && !props.blockSkip) {
            tutorialContext.completeTutorial(props.tutorialId)
        }
    }}>
        <Overlay />
        <div className='childElements' style={childElementContainerStyle}>
            {props.alternatePostiveChildren ?? props.children}
        </div>
        {helpText}
    </div>
}


