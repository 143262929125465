import { WorldTourRegion } from '@alienheadwars/football-card-manager-model';
import { Button, CardActions, CardMedia, LinearProgress, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { localise } from '../../../../locale/locale';
import { TeamContext } from "../../TeamContext";
import { WorldTourCard } from './WorldTourCard';
import { WorldTourChallengesCountCard } from './WorldTourChallenges';
import { WorldTourContext } from './WorldTourContext';


export const WorldTourRegionCard = (region: WorldTourRegion) => {

    const teamContext = useContext(TeamContext)
    const worldTourContext = useContext(WorldTourContext)
    const worldTourProgress = worldTourContext.getWorldTourProgress()
    const itemProgress = worldTourProgress.regions[region.id]
    const history = useHistory()

    const leagueChallengesCompleted = region.leagues.reduce((acc, league) => {
        const leagueProgress = worldTourProgress.leagues[league.id]
        return leagueProgress ? acc + leagueProgress.challengesComplete.length : acc
    }, 0)

    const leagueChallengesTotal = region.leagues.reduce((acc, league) => {
        return acc + league.challenges.length
    }, 0)


    return <WorldTourCard
        header={localise(`world-tour.region.${region.id}.name`)}
        subHeader={`${localise(`world-tour.region.${region.id}.description`)}${itemProgress?.unlocked ? '' : '\nComing Soon...'}`}
        disabled={!itemProgress?.unlocked}
        avatar={<CardMedia
            sx={{ border: 1, borderRadius: 2, height: '25vmin', width: '25vmin', opacity: !itemProgress?.unlocked ? 0.3 : 1 }}
            image={`/images/world-tour/${region.id}.jpg`}
            title={localise(`world-tour.region.${region.id}.name`)}
        />}
        contentId='regions'
        id={region.id}
        key={region.id}
        defaultExpanded={itemProgress?.unlocked && itemProgress.challengesComplete.length < region.challenges.length}
    >
        <LinearProgress variant="determinate" value={leagueChallengesCompleted / leagueChallengesTotal * 100} />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {leagueChallengesCompleted}/{leagueChallengesTotal} challenges completed
        </Typography>
        <WorldTourChallengesCountCard challenges={region.challenges} itemProgress={itemProgress} />
        <CardActions>
            <Button
                size="small"
                onClick={() => {
                    history.push(`${teamContext.getBaseUrl()}/play/world-tour/${region.id}`)
                    worldTourContext.setLastRegionId(region.id)
                }}>
                Play now
            </Button>
        </CardActions>
    </WorldTourCard>
}