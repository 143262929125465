import { MatchState } from '@alienheadwars/football-card-manager-model';
import React, { useState } from 'react';
import { getCallableFunction } from '../../../../../firebase/firebase';
import { ButtonComponent } from '../../../../base/button/Button';
import { HelpTextComponent } from '../../../../base/helpText/HelpTextComponent';
import { PlayAreaComponent } from '../play-area/PlayArea';
import { RoundScoreType, RoundScores } from './RoundScores';
import { RoundTotalScore } from './RoundTotalScore';
import { StrategyCardSelector } from './StrategyCardSelector';

interface PlayRoundProps {
    matchState: MatchState
    roundNumber: number
    finishRoundCallback(): void
}

export const PlayRound = (props: PlayRoundProps) => {

    const [strategyCardSelected, setStrategyCardSelected] = useState(-1)
    const [rolled, setRolled] = useState(false)
    const matchActionFunction = getCallableFunction('actionMatch')
    const roundState = props.matchState.rounds[props.roundNumber - 1]
    const playerAttacking = roundState.attackingTeam === props.matchState.homeTeam
    const mappedPlayers = (playerAttacking ? roundState.attackingPlayers : roundState.defendingPlayers)
    const playCard = () => {
        matchActionFunction({
            matchId: props.matchState.id,
            cardIndex: strategyCardSelected,
            roundNumber: props.roundNumber
        }).then(()=> props.finishRoundCallback())
    }
    const endTurn = () => {
        matchActionFunction({
            matchId: props.matchState.id,
            roundNumber: props.roundNumber
        })
        .then(()=> props.finishRoundCallback())
    }

    const roundScoreType = playerAttacking ? RoundScoreType.ATTACK : RoundScoreType.DEFENSE
    return (
        <>
            <PlayAreaComponent minute={roundState.minute}>
                {!roundState.roundFinished ? (<>
                    <RoundScores includeRolls={rolled} playerRoundStates={mappedPlayers} type={roundScoreType} />
                    {rolled ? <RoundTotalScore totalScore={playerAttacking ? roundState.attackingScore : roundState.defendingScore} /> : ''}
                    <HelpTextComponent>
                        {
                            !rolled ? `Each round consists of the highest scoring 5 attackers versus the highest scoring 5 defenders + their goalkeeper.
        It's time to ${roundScoreType}! Click Roll to see your scores`
                                : `End turn or play a tactic to try to increase your score`
                        }
                    </HelpTextComponent></>) : ''}

                {rolled && !roundState.roundFinished ?
                    <StrategyCardSelector
                        strategyCards={props.matchState.teams[props.matchState.homeTeam].strategyCards}
                        callback={(index) => { setStrategyCardSelected(index) }}
                    />
                    : ''
                }

            </PlayAreaComponent>
            {roundState.roundFinished ?
                <ButtonComponent onClick={endTurn}>Next</ButtonComponent> :
                rolled ?
                    (strategyCardSelected >= 0 ?
                        <ButtonComponent onClick={playCard} loadOnExecute>Play Card</ButtonComponent> :
                        <ButtonComponent onClick={endTurn} loadOnExecute >Finish</ButtonComponent>
                    ) :
                    <ButtonComponent onClick={() => { setRolled(true) }}>Roll</ButtonComponent>
            }
        </>
    )
}
