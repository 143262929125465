import { MatchState } from '@alienheadwars/football-card-manager-model';
import React from "react";
import './Match.css';
import { HasMatchState } from "./MatchStateViewer";

export const ScoreboardComponent = (props: HasMatchState) => {
    const matchState: MatchState = props.matchState
    const homeTeam = matchState.teams[matchState.homeTeam]
    const awayTeam = matchState.teams[matchState.awayTeam]

    return (
        <>
            <div className='score-container page-item'>
                <span className='home-box team-name' style={{
                    backgroundColor: homeTeam.colours?.primaryBackgroundColour ?? 'salmon',
                    color: homeTeam.colours?.primaryTextColour ?? 'black',
                }}>
                    {homeTeam.name}
                </span>
                <span className='home-score-box' style={{
                    backgroundColor: homeTeam.colours?.primaryColour ?? 'red',
                }}>
                    {homeTeam.score}
                </span>
            </div>
            <div className='score-container page-item'>
            <span className='away-box team-name' style={{
                    backgroundColor: awayTeam.colours?.primaryBackgroundColour ?? 'blue',
                    color: awayTeam.colours?.primaryTextColour ?? 'black',
                }}>
                    {awayTeam.name}
                </span>
                <span className='away-score-box' style={{
                    backgroundColor: awayTeam.colours?.primaryColour ?? 'lightblue',
                }}>
                    {awayTeam.score}
                </span>
            </div>
        </>
    )
}