import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { TeamContext } from "../../TeamContext";
import { NewsCardProps } from "../TeamNews";
import { ScoreboardComponent } from '../../play/match/Scoreboard';
export const UnfinishedMatch = (props: NewsCardProps) => {

    const teamContext = useContext(TeamContext)
    const history = useHistory()

    if (!teamContext.getCurrentMatch()) {
        return <></>
    }

    return props.parent(<Card>
        <CardContent>
            <Typography variant="h5" component="div">
                Unfinished match <SportsSoccerIcon />
            </Typography>
            <Typography variant="body2">
            <ScoreboardComponent matchState={teamContext.getCurrentMatch()} />
            </Typography>
        </CardContent>
        <CardActions>
            <Button
                size="small"
                onClick={() => {
                    history.push(`${teamContext.getBaseUrl()}/play`)
                }}>

                resume</Button>
        </CardActions>
    </Card>)

}
