import { WORLD_TOUR_COLLECTION_ID, WorldTourCollection, WorldTourProgress } from "@alienheadwars/football-card-manager-model";
import React, { useEffect, useState } from "react";
import { collectionManager } from "../../../../collection/collectionManager";
import { listenToDocument } from "../../../../firebase/firestore";
import { Loading } from "../../../base/loading/Loading";


export const WorldTourContext = React.createContext({
    getCollection: (): WorldTourCollection => { return undefined as unknown as WorldTourCollection },
    getWorldTourProgress: (): WorldTourProgress => { return undefined as unknown as WorldTourProgress },

    getLastRegionId: (): string | undefined => { return undefined },
    setLastRegionId: (regionId: string | undefined) => { },
    getLastLeagueId: (): string | undefined => { return undefined },
    setLastLeagueId: (leagueId: string | undefined) => { },
    getLastOpponentId: (): string | undefined => { return undefined },
    setLastOpponentId: (opponentId: string | undefined) => { }
})


export const WorldTourContextComponent = (props: { teamId: string, children: React.ReactNode }) => {

    const [worldTourProgress, setWorldTourProgress] = useState(undefined as unknown as WorldTourProgress)
    const [collection, setCollection] = useState(undefined as unknown as WorldTourCollection)
    const [lastRegionId, setLastRegionId] = useState<string | undefined>(undefined)
    const [lastLeagueId, setLastLeagueId] = useState<string | undefined>(undefined)
    const [lastOpponentId, setLastOpponentId] = useState<string | undefined>(undefined)


    useEffect(() => {
        collectionManager.getCollection(WORLD_TOUR_COLLECTION_ID).then(collection => {
            return Promise.all(
                [
                    ...Object.values(collection.regions)
                        .map(region => collectionManager.getRegion(region)
                            .then(region => { collection.regions[region.id] = region })
                        ),
                    ...Object.values(collection.teams).map(team => collectionManager.getTeam(team)
                        .then(team => { collection.teams[team.id] = team })
                    ),
                    ...Object.values(collection.leagues).map(league => collectionManager.getLeague(league)
                        .then(league => { collection.leagues[league.id] = league })
                    ),
                    Promise.all(collection.orderedRegions.map(region => collectionManager.getRegion(region))).then(regions => collection.orderedRegions = regions)
                ]).then(() => setCollection(collection as WorldTourCollection))
        })

        listenToDocument(`teams/${props.teamId}/competitionProgress/${WORLD_TOUR_COLLECTION_ID}`, setWorldTourProgress)
    }, [props.teamId])


    if (!collection || !worldTourProgress) {
        return <Loading />
    }

    return (<WorldTourContext.Provider value={{
        getCollection: () => collection,
        getWorldTourProgress: () => worldTourProgress,
        getLastRegionId: () => lastRegionId,
        setLastRegionId: setLastRegionId,
        getLastLeagueId: () => lastLeagueId,
        setLastLeagueId: setLastLeagueId,
        getLastOpponentId: () => lastOpponentId,
        setLastOpponentId: setLastOpponentId
    }}
    >
        {props.children}
    </WorldTourContext.Provider >
    )
}
