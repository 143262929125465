
import React from "react";
import { useHistory } from "react-router-dom";
import { getCallableFunction } from "../../../firebase/firebase";
import { ChoiceButtonsComponent } from "../../base/button/ChoiceButtons";
import { MainBodyTextComponent } from "../../base/mainBodyText/MainBodyText";
import { PageContainerComponent } from "../../base/pageContainer/PageContainer";
export const UnlockAllWorldTour = (props: any) => {
    const history = useHistory()
    const resetWorldTour = getCallableFunction('unlockAllWorldTour')
    return <PageContainerComponent >
        <MainBodyTextComponent>Unlock all world tour options?
        </MainBodyTextComponent>
        <ChoiceButtonsComponent
            primaryButtonText='unlock'
            primaryButtonAction={() => {
                resetWorldTour({}).then(()=>history.push('/'))
            }
            }
            secondardaryButtonText='cancel'
            secondaryButtonAction={() => history.goBack()}
        />
    </PageContainerComponent>
}