import './StackingList.css'

export enum StackingListType {
    STACKING = 'stacking',
    UNSTACKING = 'unstacking',
    BOTH = 'both'
}

export interface StackingListProps {
    elements: JSX.Element[]
    containerAdditionalStyle?: any
    type?: StackingListType
    spacing?: number
    cover?: JSX.Element
    endElementRef?: any
}

const stackingPositionFunction = (index, size, spacing) => {
    return {
        left: (index * spacing),
        transform: `translateX(50vw) translateX(-50%) translateX(-${(size / 2) * spacing}px)`
    }
}



const unStackingPositionFunction = (index, size, spacing) => {
    return {
        right: ((size - index) * spacing),
        zIndex: (size - index),
        transform: `translateX(-50vw) translateX(50%) translateX(${(size / 2) * spacing}px)`
    }
}

const bothStackingPositionFunction = (index, size, spacing) => {
    return {
        left: (index * spacing),
        right: ((size - index) * spacing),
    }
}

const functions = {
    stacking: stackingPositionFunction,
    unstacking: unStackingPositionFunction,
    both: bothStackingPositionFunction
}

export const StackingList = (props: StackingListProps) => {
    const positionFunction = functions[props.type ?? StackingListType.STACKING]
    const elements = [...props.elements]
    const cards = elements.map((element, index) => {
        return (
            <li className='card-container' style={positionFunction(index, elements.length, props.spacing ?? 2)}>{element}</li>
        )
    })
    return (<ul className='cards-container' style={props.containerAdditionalStyle ?? {}}>
        {props.cover ? (<li className='cover-container' style={{ zIndex: elements.length + 1 }}>{props.cover}</li>) : ''}
        {cards}
        {props.endElementRef ? <li ref={props.endElementRef}></li> : ''}
        {/* works around https://bugzilla.mozilla.org/show_bug.cgi?id=1488080 */}
        <div style={
            {
                color:'green',
                opacity:.1
            }
        }>.</div>
    </ul>)
}
