import '../../../../cards/CardColours.css'
import { useState } from "react";
import './RoundScores.css'
import { Overlay } from '../../../../base/overlay/Overlay';
import { HelpOverlay } from '../../../../base/tutorial/helpOverlay/HelpOverlay';
import { AttributeExtraction, MiniPlayerCard } from '../../../../cards/playerCards/MiniPlayerCard';
import { PlayerCardComponent } from '../../../../cards/playerCards/PlayerCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { Die } from '../dice/dice';
import { PlayerRoundState } from '@alienheadwars/football-card-manager-model';
import React from 'react';
export enum RoundScoreType {
    ATTACK = 'attack',
    DEFENSE = 'defense'
}

export interface RoundScoresProps {
    playerRoundStates: PlayerRoundState[]
    type: RoundScoreType
    includeRolls?: boolean
}

const inspiration =
    <FontAwesomeIcon icon={faCirclePlus} size='xs' className='player-inspiration' />
const fatigue = <FontAwesomeIcon icon={faCircleMinus} size='xs' className='player-fatigue' />


export const RoundScores = (props: RoundScoresProps) => {
    const attributes: AttributeExtraction<PlayerRoundState>[] = []
    attributes.push(
        props.type === RoundScoreType.ATTACK ?
            player => <div className="player-attribute player-attack">{player.attack + (player.bonus ?? 0)}</div> :
            player => <div className="player-attribute player-defense">{player.defense + (player.bonus ?? 0)}</div>)

    if (props.includeRolls) {
        if (player => (player.modifiedRoll ?? player.roll))
            attributes.push(player => (player.modifiedRoll ?? player.roll) ?
                <div className="player-attribute player-dice-roll"><div className={`floating-operator ${player.level.toLowerCase()}-border`}>+</div> <Die value={player.modifiedRoll ?? player.roll} /></div>
                : '');
        attributes.push(player => <div className="player-attribute player-score"><div className={`floating-operator ${player.level.toLowerCase()}-border`}>=</div> {player.score}</div>)
    }


    const [playerCardOverlay, setPlayerCardOverlay] = useState(-1)
    const playerScores = props.playerRoundStates.map((playerRoundState, index) => {
        return <div className='player-padding'>
            <MiniPlayerCard
                player={playerRoundState}
                attributes={attributes}
                selected={props.includeRolls && playerRoundState.included}
                onClick={() => setPlayerCardOverlay(index)}
            />
            {playerRoundState.bonus > 0 ?
                <div className="player-bonus">
                    {[...Array(playerRoundState.bonus)].map(() => inspiration)}
                </div>
                : ''}
            {playerRoundState.bonus < 0 ?
                <div className="player-bonus">
                    {[...Array(-playerRoundState.bonus)].map(() => fatigue)}
                </div>
                : ''}
        </div>
    })

    const playersWithInspiration = playerScores.filter((card, index) => props.playerRoundStates[index].bonus > 0)
    const playersWithfatigue = playerScores.filter((card, index) => props.playerRoundStates[index].bonus < 0)


    if (RoundScoreType.ATTACK === props.type) {
        playerScores.reverse()
    }

    playerScores[0] = < HelpOverlay
        tutorialId='roundScoresPlayerScore'
        helpText='The player scores are made up of their attribute and the dice role'
        additionalCondition={props.includeRolls}
    >
        {playerScores[0]}
    </HelpOverlay>

    return (
        < HelpOverlay
            helpText='The 5 highest scores for the round are included in the attack, players included have a green outline.'
            tutorialId='roundScores'
            additionalCondition={props.includeRolls}
        >
            < HelpOverlay
                tutorialId='inspiration'
                helpText={<>
                    Players who rolled a <Die value={6} /> in a previous round have gained an inspiration bonus! {inspiration}<br />
                    This will add +1 to their attack and defense for the remainder of the game.<br />
                    The player will lose a point of insipriation if they roll a <Die value={1} /><br />
                    A player can have at most 3 inspiration.
                </>
                }
                alternatePostiveChildren={<div className="round-players">{playersWithInspiration}</div>}
                additionalCondition={playersWithInspiration.length > 0}
            >
                < HelpOverlay
                    tutorialId='fatigue'
                    helpText={<>
                        Players who rolled a <Die value={1} /> in a previous round have suffered fatigue penalty!  {fatigue}<br />
                        This will subtract 1 to their attack and defense for the remainder of the game.<br />
                        The player will recover from a point of fatigue if they roll a <Die value={6} /><br />
                        A player can have at most 3 fatigue.
                    </>
                    }
                    alternatePostiveChildren={<div className="round-players">{playersWithfatigue}</div>}
                    additionalCondition={playersWithfatigue.length > 0}
                >
                    <div className="round-players">{playerScores}</div>
                </HelpOverlay>
            </HelpOverlay>
            {playerCardOverlay >= 0 ?
                <Overlay>
                    <PlayerCardComponent player={props.playerRoundStates[playerCardOverlay]} onClick={() => setPlayerCardOverlay(-1)} />
                </Overlay> : ''
            }

        </HelpOverlay>
    )

}