import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MainBodyTextComponent } from "../../base/mainBodyText/MainBodyText";
import { HelpOverlay } from "../../base/tutorial/helpOverlay/HelpOverlay";
import { TeamContext } from "../TeamContext";
import { Lineup } from "./Lineup";

export const SelectLineup = () => {

    const teamContext = useContext(TeamContext)
    const history = useHistory()
    const [chosenPlayer, setChosenPlayer] = useState(-1)

    //TODO, allow user to create lineup from scratch or get backend to prepopulate
    useEffect(() => {
        if (!teamContext.getTeamConfig() || teamContext.getTeamConfig().lineups.default.startingLineup.length < 11) {
            teamContext.updateTeamConfig(existingConfig => {
                const config = (existingConfig ?? { lineups: { default: { startingLineup: [] } } })
                config.lineups.default.startingLineup = [...teamContext.getTeam().players].slice(0, 11)
                return config
            })
        }
    }, [teamContext]
    )

    useEffect(() => {
        if (chosenPlayer !== -1) {
            history.push(teamContext.getBaseUrl() + '/editLineup/default')
        }
    }, [chosenPlayer, history, teamContext])

    if (!teamContext.getTeamConfig()) {
        return <></>
    }
    return (
        <>
            <MainBodyTextComponent>Make any changes to your lineup before your next match</MainBodyTextComponent>
            <HelpOverlay helpText='You may have better players on your bench, click on a player to make a sub' tutorialId='lineupChange' textHeight='10vh' blockSkip={true}>
                {teamContext.getTeamConfig().lineups.default.startingLineup.length < 11 ? '' :
                    <Lineup callback={(index) => { setChosenPlayer(index) }} players={teamContext.getTeamConfig().lineups.default.startingLineup} />
                }
            </HelpOverlay>
            <MainBodyTextComponent>Click on a player to swap them with a sub</MainBodyTextComponent>
        </>
    )
}