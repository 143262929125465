import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from "../../firebase/firebase";
import { HelpTextComponent } from "../base/helpText/HelpTextComponent";
import { PageContainerComponent } from "../base/pageContainer/PageContainer";
import { PlainSection } from "../base/plainSection/PlainSection";
import { Title } from "../base/title/Title";
import './SignIn.css';
import StyledFirebaseAuth from './StyledFirebaseAuth';

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/teams/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      GoogleAuthProvider.PROVIDER_ID,
  ],
};

export const SignIn = () => {
  
  const history = useHistory()
  //Sign user in if already signed in
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(user => {
      if (user) {
        history.push('/teams/')
      }
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainerComponent>
      <div className='title-container'>
        <Title light={false}>FOOTBALL CARD MANAGER</Title>
      </div>
      <PlainSection>
        <h1 className='welcome-text'>Welcome</h1>
        <HelpTextComponent>Welcome to football card mananger, please sign in</HelpTextComponent>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </PlainSection>
    </PageContainerComponent>
  )
}