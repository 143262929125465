import React from 'react';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import './App.css';
import { ThemeComponent } from './components/base/styles/Theme';
import { TutorialTracker } from './components/base/tutorial/tutorialTracker/TutorialTracker';
import { CreateTeamComponent } from './components/create-team/CreateTeam';
import { Options } from './components/options/Options';
import { SignIn } from './components/sign-in/SignIn';
import { TeamBase } from './components/team/TeamBase';

function App() {
  return (
  
    <Router>

      <div>


        <ThemeComponent>
          <Switch>
            <Route exact path="/">
              <SignIn />
            </Route>
            <TutorialTracker>

              <Route path="/options" component={Options} />
              <Route path="/teams*">
                  <Switch>
                    <Route exact path="/teams">
                      <CreateTeamComponent />
                    </Route>
                    <Route path="/teams/:teamId" component={TeamBase} />
                  </Switch>
              </Route>
            </TutorialTracker>
          </Switch>
        </ThemeComponent>
      </div>
    </Router>



  );
}

export default App;
