import React from "react"
import { Route, Switch } from "react-router-dom"
import { TeamNews } from "./TeamNews"
import { NewPackListPage } from "./newpack/NewPackListPage"
import { ViewPackInstance } from "./newpack/ViewPackInstance"


export const TeamHomeComponent = () => {

    // const teamContext = useContext(TeamContext)
    // if (teamContext.getTeam().unopenedPacks && teamContext.getTeam().unopenedPacks.length > 0) {
    //     return (
    //         //key should be a uid, but packs don't yet have them
    //         <NewPackPage key={teamContext.getTeam().unopenedPacks[0].id} pack={teamContext.getTeam().unopenedPacks[0] as PlayerPack} />
    //     )
    // }

    // return <SelectLineup />

    return <Switch>
        <Route exact path="/teams/:teamId/myteam" component={TeamNews} />
        <Route exact path="/teams/:teamId/myteam/packs" component={NewPackListPage} />
        <Route path="/teams/:teamId/myteam/packs/:packId" component={ViewPackInstance} />
    </Switch>
}