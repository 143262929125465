import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    starterPack: 'Starter Pack',
    copperPack: 'Consolation Copper Pack',
    bronzePack: 'Brilliant Bronze Pack',
    silverPack: 'Super Silver Pack',
    goldPack: 'Great Gold Pack',
    platinumPack: 'Phenomenal Platinum Pack',
    diamondPack: 'Dazzling Diamond Pack',
    encourageAbbreviation: 'E',
    encourageTitle: 'Encourage',
    encourageDescription: 'All your players who rolled a 1 will re-roll.',
    totalFootballAbbreviation: 'TF',
    totalFootballTitle: 'Total Football',
    totalFootballDescription: 'Every outfield player on both teams is included in round.',
    waterBreakTitle: 'Water break',
    waterBreakDescription: 'All your players will recover from 1 fatigue',

    COPPER: 'copper',
    BRONZE: 'bronze',
    SILVER: 'silver',
    GOLD: 'gold',
    PLATINUM: 'platinum',
    DIAMOND: 'diamond',

    'gb-eng': 'England',
    'gb-sct': 'Scotland',
    'gb-wls': 'Wales',
    'it': 'Italy',


    'world-tour.name': 'World Tour',

    'world-tour.locktext.previous-team': 'Beat the previous team to unlock this one',

    'world-tour.region.great-britain.name': 'Great Britain',
    'world-tour.region.great-britain.description': 'Embrace the thrill of the fight as you begin your tour in Great Britain, where unexpected results and passionate play rule the day.',
    // africa
    'world-tour.region.africa.name': 'Africa',
    'world-tour.region.africa.description': 'Embark on a vibrant adventure across Africa, where diverse landscapes and resilient teams await your challenge.',

    // asia
    'world-tour.region.asia.name': 'Asia',
    'world-tour.region.asia.description': 'Traverse the vast continent of Asia, encountering legendary teams steeped in tradition and unwavering spirit.',

    // central-europe
    'world-tour.region.central-europe.name': 'Central Europe',
    'world-tour.region.central-europe.description': 'Navigate the strategic landscape of Central Europe, where disciplined teams and a legacy of tactical innovation await.',

    // north-america
    'world-tour.region.north-america.name': 'North America',
    'world-tour.region.north-america.description': 'Test your skills in North America, home to fast-paced play and teams driven by relentless ambition.',

    // oceania
    'world-tour.region.oceania.name': 'Oceania',
    'world-tour.region.oceania.description': 'Explore the island nations of Oceania, where passionate teams play with a fierce and unique island spirit.',

    // south-america
    'world-tour.region.south-america.name': 'South America',
    'world-tour.region.south-america.description': 'Experience the fiery passion of South America, where technical brilliance and undeniable flair define the teams.',

    // south-europe
    'world-tour.region.south-europe.name': 'Southern Europe',
    'world-tour.region.south-europe.description': 'Embrace the warmth of Southern Europe, where expressive play and a flair for the dramatic define the competition.',

    'world-tour.league.great-britain/sunday-league.name': 'Sunday League',
    'world-tour.league.great-britain/sunday-league.description': 'Where passion outpaces polish and camaraderie clashes with competitive fire.',

    'world-tour.league.great-britain/welsh-league.name': 'Wales',
    'world-tour.league.great-britain/welsh-league.description': 'Prepare to test your mettle against teams steeped in passion, resilience, and a touch of magic.',
    'world-tour.league.great-britain/welsh-league.locktext': 'Beat every team in the Sunday league to unlock Wales',

    'world-tour.league.great-britain/scottish-league.name': 'Scotland',
    'world-tour.league.great-britain/scottish-league.description': 'A crucible of soccer heritage, brimming with fervent teams that embody the rugged, indomitable spirit of Scotland.', 'world-tour.league.great-britain/scottish-league.locktext': 'Beat every team in Wales to unlock Scotland',
    'world-tour.league.scotland.locktext': 'Beat every team in Wales to unlock Scotland',

    'world-tour.league.great-britain/english-league.name': 'England',
    'world-tour.league.great-britain/english-league.description': 'Step into the heart of football nobility where legends are born and rivalries run deep.',
    'world-tour.league.great-britain/english-league.locktext': 'Beat every team in Scotland to unlock England',

    'world-tour.league.south-europe/italian-league.name': 'Serie Novanta',
    'world-tour.league.south-europe/italian-league.description': "Enter the Gladiator's Arena: 'Step into a league where every match is a battle and legends are forged in the heat of competition",
    'world-tour.league.south-europe/italian-league.locktext': 'Beat every team in England to unlock Italy',

    'world-tour.challenge.play': 'Play a game against this team',
    'world-tour.challenge.win': 'Defeat this team',
    'world-tour.challenge.win-by-x': 'Defeat this team by _x_ or more goals',
    'world-tour.challenge.win-same-league': 'Defeat this team by using only players from the _league_ league',
    'world-tour.challenge.win-same-nation': 'Defeat this team by using only players from the _nation_ nation',


    'world-tour.challenge.BEAT': 'Defeat this team',
    'world-tour.challenge.x-team-stars': 'Complete _x_ team challenges',
    'world-tour.challenge.x-league-stars': 'Complete _x_ league challenges',

    'pack.starter-pack.name': 'Starter pack',
    'pack.starter-pack.description': "Contains 14 players to start your club's journey",

    'pack.world-tour-pack.name': 'World tour _level_ pack',
    'pack.world-tour-pack.description': 'Contains 5 world tour players, with 1 guaranteed to be _level_ or higher.',

    'pack.world-tour-team-pack.name': 'World tour _teamName_ _level_ pack',
    'pack.world-tour-team-pack.description': "Contains 5 world tour players, with 1 player guaranteed to be _level_ or higher from '_teamName_'.",

    'pack.world-tour-league-pack.name': 'World tour _leagueId_ _level_ pack',
    'pack.world-tour-league-pack.description': "Contains 5 world tour players, with 1 guaranteed to be _level_ or higher from the '_leagueId_'",

    'pack.world-tour-nation-pack.name': 'World tour _nation_ _level_ pack',
    'pack.world-tour-nation-pack.description': "Contains 5 world tour players, with 1 guaranteed to be _level_ or higher from '_nation_'",


    'ability-placeholder-name': 'Placeholder ability',
    'ability-placeholder-description': 'Coming soon! Cards may have special abilities.',
    'ability-placeholder2-name': 'Placeholder ability',
    'ability-placeholder2-description': 'This card is legendary and may have a second ability',
    'ability-versatile-name': 'Versatile',
    'ability-versatile-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score.',
    'ability-determined-name': 'Determined',
    'ability-determined-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score.',
    'ability-quick_off_the_mark-name': 'Quick off the mark',
    'ability-quick_off_the_mark-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score.',
    'ability-inspiring_play-name': 'Inspiring play',
    'ability-inspiring_play-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-man_marker-name': 'Man Marker',
    'ability-man_marker-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-hard_tackler-name': 'Hard Tackler',
    'ability-hard_tackler-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-teamwork-name': 'Teamwork',
    'ability-teamwork-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-strong-name': 'Strong',
    'ability-strong-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-pace_abuse-name': 'Pace Abuse',
    'ability-pace_abuse-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-poacher-name': 'Poacher',
    'ability-poacher-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-brick_wall-name': 'Brick Wall',
    'ability-brick_wall-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-rocket_shot-name': 'Rocket Shot',
    'ability-rocket_shot-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-free_kick_specialist-name': 'Free Kick Specialist',
    'ability-free_kick_specialist-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-moments-name': 'Moments',
    'ability-moments-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-underdog-name': 'Underdog',
    'ability-underdog-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-super_sub-name': 'Super Sub',
    'ability-super_sub-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-killer_ball-name': 'Killer Ball',
    'ability-killer_ball-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-playmaker-name': 'Playmaker',
    'ability-playmaker-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-engine-name': 'Engine',
    'ability-engine-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-anticipation-name': 'Anticipation',
    'ability-anticipation-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-wing_man-name': 'Wing Man',
    'ability-wing_man-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score.',
    'ability-iron_man-name': 'Iron Man',
    'ability-iron_man-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score.',
    'ability-silky-name': 'Silky',
    'ability-silky-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score.',
    'ability-cat-name': 'Cat',
    'ability-cat-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-long_arms-name': 'Long Arms',
    'ability-long_arms-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-steady-name': 'Steady',
    'ability-steady-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-power_header-name': 'Power Header',
    'ability-power_header-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-heart_in_sleeve-name': 'Heart in Sleeve',
    'ability-heart_in_sleeve-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-perfectionist-name': 'Perfectionist',
    'ability-perfectionist-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-passing_master-name': 'Passing Master',
    'ability-passing_master-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-trickster-name': 'Trickster',
    'ability-trickster-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-terrier-name': 'Terrier',
    'ability-terrier-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-reliable-name': 'Reliable',
    'ability-reliable-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-talented-name': 'Talented',
    'ability-talented-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-distraction-name': 'Distraction',
    'ability-distraction-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-pressing_forward-name': 'Pressing Forward',
    'ability-pressing_forward-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-work_horse-name': 'Work Horse',
    'ability-work_horse-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-one_man_show-name': 'One Man Show',
    'ability-one_man_show_description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-fighter-name': 'Fighter',
    'ability-fighter-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the defense score',
    'ability-finisher-name': 'Finisher',
    'ability-finisher-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-captain-name': 'Captain',
    'ability-captain-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-vision-name': 'Vision',
    'ability-vision-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-dribbler-name': 'Dribbler',
    'ability-dribbler-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-flair-name': 'Flair',
    'ability-flair-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-future_star-name': 'Future Star',
    'ability-future_star-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',
    'ability-fallen_star-name': 'Fallen Star',
    'ability-fallen_star-description': 'Coming soon! Cards may have complex special abilities. For now this ability simply adds +1 to the attack score',



    'tutorial.newPack': 'You have a pack of football cards to open!'
  },
});

export const localise = (propertyName: string): string => {
  return strings[propertyName] ?? propertyName
}

export const localiseAndReplace = (props: { id: string, replacements: { [key: string]: string } }): string => {
  var localised = localise(props.id)
  for (const [key, value] of Object.entries(props.replacements ?? {})) {
    localised = localised.replace(`_${key}_`, localise(value))
  }
  return localised
}