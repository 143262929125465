import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, CardHeader, SxProps, Theme } from "@mui/material";
import React from "react";

export interface WorldTourCardProps {
    defaultExpanded: boolean | undefined;
    contentId: string | undefined;
    subHeader: any;
    sx?: SxProps<Theme>;
    headerSx?: SxProps<Theme>;
    disabled?: boolean;
    header: any;
    avatar?: any;
    children: any;
    id: any;
}

export const WorldTourCard = (props: WorldTourCardProps) => {


    return <Accordion disabled={props.disabled} sx={props.sx} defaultExpanded={props.defaultExpanded}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={props.contentId}
            id={props.id}
        >
            <CardHeader
                avatar={props.avatar}
                title={props.header}
                subheader={props.subHeader}
                sx={props.headerSx}
            />
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
}