import { Stack } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { WorldTourContext } from "./WorldTourContext";
import { WorldTourRegionCard } from "./WorldTourRegionCard";
import { WorldTourRegionHome } from "./WorldTourRegionHome";
import { TeamContext } from "../../TeamContext";


export const WorldTourHome = () => {
    const worldTourContext = useContext(WorldTourContext)
    const teamContext = useContext(TeamContext)


    const regions = worldTourContext.getCollection().orderedRegions.map(region => <WorldTourRegionCard {...region} />)
    const resumeUrl = useMemo(() => {
        const redirectPart = worldTourContext.getLastLeagueId() ?? worldTourContext.getLastRegionId() ?? ''
        return `/teams/${teamContext.getTeam().id}/play/world-tour/` + redirectPart
    }, [teamContext, worldTourContext])

    return (<>
        <Switch>
            <Route exact path="/teams/:teamId/play/world-tour">
                <Stack spacing={2}>
                    {regions}
                </Stack>
            </Route>
            <Route exact path="/teams/:teamId/play/world-tour/resume">
                <Redirect to={resumeUrl} />
            </Route>
            <Route path="/teams/:teamId/play/world-tour/:regionId" component={WorldTourRegionHome} />
        </Switch>
    </>
    )
}

