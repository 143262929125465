
import { useHistory } from "react-router-dom";
import { auth } from "../../../firebase/firebase";
import { ChoiceButtonsComponent } from "../../base/button/ChoiceButtons";
import { MainBodyTextComponent } from "../../base/mainBodyText/MainBodyText";
import { PageContainerComponent } from "../../base/pageContainer/PageContainer";
export const SignOut = (props: any) => {
    const history = useHistory()
    return <PageContainerComponent long={true}>
        <MainBodyTextComponent>Sign out from football card manager?
        </MainBodyTextComponent>
        <ChoiceButtonsComponent
            primaryButtonText='sign out'
            primaryButtonAction={() => {
                auth.signOut().then(()=>history.push('/'))
            }
            }
            secondardaryButtonText='cancel'
            secondaryButtonAction={() => history.goBack()}
        />
    </PageContainerComponent>
}