import { MatchState, RoundState } from '@alienheadwars/football-card-manager-model';
import '../Match.css'
import { RoundScores, RoundScoreType } from "./RoundScores";
import React from 'react';

export interface RoundSummaryProps {
    roundState: RoundState
    matchState: MatchState
}

export const RoundSummary = (props: RoundSummaryProps) => {

    const homeAttacking = props.roundState.attackingTeam === props.matchState.homeTeam
    const attackingTeam = props.matchState.teams[props.roundState.attackingTeam]
    const defendingTeam = props.matchState.teams[props.roundState.defendingTeam]
    const attackingScore = <div className='score-container'>
        <span className={`${homeAttacking ? 'home' : 'away'}-box team-name`}>{attackingTeam.name}</span> <span className='score-box'>{props.roundState.attackingScore}</span>
    </div>
    const defendingScore = <div className='score-container'>
        <span className={`${!homeAttacking ? 'home' : 'away'}-box team-name`}>{defendingTeam.name}</span> <span className='score-box'>{props.roundState.defendingScore}</span>
    </div>
    return <div className='round-summary-container'>


        <RoundScores playerRoundStates={props.roundState.attackingPlayers} type={RoundScoreType.ATTACK} includeRolls={true} />
        {attackingScore}
        {defendingScore}
        <RoundScores playerRoundStates={props.roundState.defendingPlayers} type={RoundScoreType.DEFENSE} includeRolls={true} />

    </div>
}

