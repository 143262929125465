import { MatchState, PackInstance, Team, TeamConfig } from "@alienheadwars/football-card-manager-model";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collectionManager } from "../../collection/collectionManager";
import { toJson } from "../../firebase/firebase";
import { listenToDocument, listenToQuery, putDocument } from "../../firebase/firestore";
import { Loading } from "../base/loading/Loading";
import { WorldTourContextComponent } from "./play/worldTour/WorldTourContext";

export interface UpdateTeamConfigFunction {
    (teamConfig: TeamConfig): TeamConfig
}

export const TeamContext = React.createContext({
    getTeamConfig: (): TeamConfig => { return undefined as unknown as TeamConfig },
    updateTeamConfig: (updateTeamConfigFunction: UpdateTeamConfigFunction) => { },
    getTeam: (): Team => { return undefined as unknown as Team },
    getBaseUrl: (): string => { return '/teams/123' },
    getCurrentMatch: (): MatchState => { return undefined as unknown as MatchState },
    getUnopenedPacks: (): PackInstance[] => { return [] as PackInstance[] },
})

export interface TeamContextComponentProps {
    children: any,
}

export interface TeamUrlParams {
    teamId: string
}

export const TeamContextComponent = (props: TeamContextComponentProps) => {

    const teamId = useParams<TeamUrlParams>().teamId

    const [teamConfig, setTeamConfig] = useState(undefined as unknown as TeamConfig)
    const [team, setTeam] = useState(undefined as unknown as Team)
    const [matchState, setMatchState] = useState(undefined as unknown as MatchState)
    const [unopenedPacks, setUnopenedPacks] = useState([] as PackInstance[])

    const setupListeners = useCallback(() => {
        if (teamId) {

            listenToDocument(`teams/${teamId}`, async (team:Team)=>{
                // lookup latest players
                team.players = await Promise.all(team.players.map((player)=>{return collectionManager.getPlayer(player)}))
                setTeam(team)
            })

            listenToQuery('matches',
                [
                    { field: 'homeTeam', operator: '==', value: teamId },
                    { field: 'matchFinished', operator: '==', value: false }
                ],
                (matches: MatchState[]) => {
                    if (!matchState && matches.length > 0) {
                        setMatchState(matches[0])
                    }
                    else {
                        setMatchState(undefined as unknown as MatchState)
                    }
                }
            )

            listenToQuery('teams/' + teamId + '/packs', [{ field: 'opened', operator: '==', value: false }], setUnopenedPacks)

            listenToDocument(`teams/${teamId}/teamconfig/default`, setTeamConfig)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamId])

    useEffect(() => {
        setupListeners()
    }, [setupListeners])


    if (!team) {
        return <Loading />
    }

    return (<TeamContext.Provider value={{
        getTeam: () => team,
        getTeamConfig: () => teamConfig,
        updateTeamConfig: (updateTeamConfigFunction: UpdateTeamConfigFunction) => {
            setTeamConfig(existingConfig => {
                const newConfig = updateTeamConfigFunction(existingConfig)
                putDocument(`teams/${teamId}/teamconfig/default`, toJson(newConfig))
                return newConfig
            })
        },
        getBaseUrl: () => `/teams/${teamId}`,
        getCurrentMatch: () => matchState,
        getUnopenedPacks: () => unopenedPacks,
    }}
    >
        {/* ugly, sort this dependency triangle */}
        <WorldTourContextComponent teamId={teamId}>
            {props.children}</WorldTourContextComponent>
    </TeamContext.Provider >
    )
}
