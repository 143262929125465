import { Level, Position } from '@alienheadwars/football-card-manager-model';
import PersonIcon from '@mui/icons-material/Person';
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { MiniPlayerCard } from '../../cards/playerCards/MiniPlayerCard';
import { NewPackNewsCard } from "./newpack/NewPackNewsCard";
import { UnfinishedMatch } from './unfinishedMatch/UnfinishedMatchCard';

export interface NewsCardProps {
    parent(children: any): any
}

export const TeamNews = () => {

    const legendaryCards = <Grid item><Card>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Coming soon
            </Typography>
            <Typography variant="h5" component="div" >GOATS</Typography>
            <Typography variant="body2">
                Collect the greatest players in football history, each with their own special abilities to spice up a match.
            </Typography>
            <Stack direction="row" spacing={2}>
                <MiniPlayerCard player={{
                    level: Level.DIAMOND,
                    position: Position.FWD,
                    name: 'GOAT',
                    attack: 8,
                    defense: 4,
                    avatar: [
                        {
                            type: 'image/jpg',
                            componentName: 'goats/argentinianGoat',
                        },
                    ],
                    abilities: [
                        {
                            id: 'placeholder'
                        },
                        {
                            id: 'placeholder2'
                        }
                    ],
                    nationId: 'ar'
                }
                }
                />
                <MiniPlayerCard player={{
                    level: Level.DIAMOND,
                    position: Position.ST,
                    name: 'Portugese Devil',
                    attack: 9,
                    defense: 3,
                    avatar: [
                        {
                            type: 'image/jpg',
                            componentName: 'goats/portugeseDevil',
                        },
                    ],
                    abilities: [
                        {
                            id: 'placeholder'
                        },
                        {
                            id: 'placeholder2'
                        }
                    ],
                    nationId: 'pt'
                }
                }
                />
                <MiniPlayerCard player={{
                    level: Level.DIAMOND,
                    position: Position.FWD,
                    name: 'Brazillian King',
                    attack: 8,
                    defense: 4,
                    avatar: [
                        {
                            type: 'image/jpg',
                            componentName: 'goats/brazillianKing',
                        },
                    ],
                    abilities: [
                        {
                            id: 'placeholder'
                        },
                        {
                            id: 'placeholder2'
                        }
                    ],
                    nationId: 'br'
                }
                }
                />
                <MiniPlayerCard player={{
                    level: Level.DIAMOND,
                    position: Position.FWD,
                    name: 'The Brazillian Queen',
                    attack: 8,
                    defense: 4,
                    avatar: [
                        {
                            type: 'image/jpg',
                            componentName: 'goats/brazillianQueen',
                        },
                    ],

                    abilities: [
                        {
                            id: 'placeholder'
                        },
                        {
                            id: 'placeholder2'
                        }
                    ],
                    nationId: 'br'
                }} />
                <MiniPlayerCard player={{
                    level: Level.DIAMOND,
                    position: Position.FWD,
                    name: 'Warrior Princess',
                    attack: 8,
                    defense: 4,
                    avatar: [
                        {
                            type: 'image/jpg',
                            componentName: 'goats/warriorPrincess',
                        },
                    ],

                    abilities: [
                        {
                            id: 'placeholder'
                        },
                        {
                            id: 'placeholder2'
                        }
                    ],
                    nationId: 'us'
                }} />
            </Stack>
        </CardContent>
    </Card></Grid>


    const teamColours = <Grid item><Card>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Coming soon
            </Typography>
            <Typography variant="h5" component="div">
                Team Colours <PersonIcon color='primary' />
            </Typography>
            <Typography variant="body2">
                Choose your colours! Unlock new kits through challenges. What colour will you be?
            </Typography>
        </CardContent>
    </Card></Grid>


    const parent = (children: any) => {
        return <Grid item xs>{children}</Grid>
    }
    return (
        <Grid container spacing={2}>
            <NewPackNewsCard parent={parent} />
            <UnfinishedMatch parent={parent} />
            {teamColours}
            {legendaryCards}
        </Grid>
    )
}