"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Position = void 0;
var Position;
(function (Position) {
    Position["GK"] = "GK";
    Position["RWB"] = "RWB";
    Position["RB"] = "RB";
    Position["CB"] = "CB";
    Position["LB"] = "LB";
    Position["LWB"] = "LWB";
    Position["CDM"] = "CDM";
    Position["RM"] = "RM";
    Position["CM"] = "CM";
    Position["LM"] = "LM";
    Position["RW"] = "RW";
    Position["AM"] = "AM";
    Position["LW"] = "LW";
    Position["FWD"] = "FWD";
    Position["ST"] = "ST";
})(Position || (exports.Position = Position = {}));
