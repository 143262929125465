import './StrategyCardSelector.css'
import { useState } from "react";
import { localise } from "../../../../../locale/locale";
import { HelpOverlay } from "../../../../base/tutorial/helpOverlay/HelpOverlay";
import { StrategyCard } from "../../../../cards/strategyCards/StrategyCard";
import { StrategyCardState } from '@alienheadwars/football-card-manager-model';
import React from 'react';

export interface StrategyCardSelectorProps {
    strategyCards: StrategyCardState[]
    callback(selected: number): void
}

export const StrategyCardSelector = (props: StrategyCardSelectorProps) => {
    const [selected, setSelected] = useState(-1)
    const strategyCards = []
    props.strategyCards.forEach((strategyCard, index) => {
        if (strategyCard.played) {
            return
        }
        const onClick = () => {
            const newIndex = selected === index ? -1 : index
            setSelected(newIndex)
            props.callback(newIndex)
        }
        strategyCards.push(<div
            className='strategy-card-padding'
            onClick={onClick}>
            <StrategyCard strategyCard={strategyCard} selected={selected === index} />
        </div>)
    })
    return (
        <HelpOverlay tutorialId='strategyCardSelector' helpText='You can play a tactic card to try and improve your score or click finish to continue' textHeight='30vh' backgroundColour='white'>
            <div className='strategy-card-container'>{strategyCards}</div>
            {selected >= 0 ?
                <span className='strategy-card-description'>
                    {localise(`${props.strategyCards[selected].name}Title`)}: {localise(`${props.strategyCards[selected].name}Description`)}
                </span> : ''}
        </HelpOverlay>
    )
}