import { PackInstance } from "@alienheadwars/football-card-manager-model"
import { Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { getCallableFunction } from "../../../../firebase/firebase"
import { listenToDocument } from "../../../../firebase/firestore"
import { ButtonComponent } from "../../../base/button/Button"
import { Loading } from "../../../base/loading/Loading"
import { PageContainerComponent } from "../../../base/pageContainer/PageContainer"
import { StackingList, StackingListType } from "../../../cards/StackingList"
import { PlayerCardComponent } from "../../../cards/playerCards/PlayerCard"
import { TeamContext } from "../../TeamContext"
import './Pack.css'

export interface PackUrlParams {
    teamId: string,
    packId: string,
}

export const ViewPackInstance = () => {

    const teamContext = useContext(TeamContext)
    const history = useHistory()
    const packUrlParams = useParams<PackUrlParams>()

    const [pack, setPack] = useState<PackInstance>(undefined as unknown as PackInstance)
    const [openPackPromise, setOpenPackPromise] = useState<Promise<any>>(undefined as unknown as Promise<any>)


    useEffect(() => {
        const existingPack = teamContext.getUnopenedPacks()?.find(pack => pack.uid === packUrlParams.packId)
        if (existingPack) {
            setPack(existingPack)
        }
        listenToDocument(`teams/${packUrlParams.teamId}/packs/${packUrlParams.packId}`, setPack)
    }, [teamContext, packUrlParams]
    )
    useEffect(() => {
        if (pack && !pack.opened && !openPackPromise) {
            const openPackFunction = getCallableFunction('v2OpenPack')
            setOpenPackPromise(openPackFunction({ packId: pack.uid }))
        }
    }, [pack, openPackPromise]
    )

    if (!pack) {
        return <Loading />
    }



    const confirmAction = async () => {
        if (openPackPromise) {
            await openPackPromise
        }
        history.goBack()
    }

    const players = pack.assignedItems.map((player, index) => {
        return <div className='pack-card'><PlayerCardComponent player={player} /></div>
    }).reverse()
    return (
        <PageContainerComponent key={pack.uid}>
            <Typography align="center" variant="h3" component="div">Great news!</Typography>
            <Typography align="center" variant="h4" component="div">You have a new pack!</Typography>
            <Typography align="center" variant="h5" component="div">Swipe left to view contents</Typography>
            <StackingList
                elements={players}
                type={StackingListType.UNSTACKING}
                containerAdditionalStyle={{ paddingTop: 30, paddingBottom: 30 }}
                cover={<img
                    src={`/images/${pack.art[0].image}.png`}
                    className='pack'
                    alt={pack.id}
                />}
            />
            <ButtonComponent onClick={confirmAction} loadOnExecute> THAT'S GREAT!</ButtonComponent>
        </PageContainerComponent>

    )
}

