import {  V2Pack } from "@alienheadwars/football-card-manager-model"
import { Snackbar, Button } from "@mui/material"
import React, { useEffect } from "react"
import { useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { TeamContext } from "../../TeamContext"


export const NewPackSnack = () => {

    const [unopenedPacks, setUnopenedPacks] = useState([] as V2Pack[])
    const [open, setOpen] = useState(false)
    const onClose = () => {
        setOpen(false)
    }
    const teamContext = useContext(TeamContext)
    const history = useHistory()

    useEffect(() => {
        if (unopenedPacks !== teamContext.getUnopenedPacks()) {
            //Show again if new packs
            if (unopenedPacks.length < teamContext.getUnopenedPacks().length) {
                setOpen(true)
            }
            setUnopenedPacks(teamContext.getUnopenedPacks())
        }
    }, [unopenedPacks, teamContext])

    return (<Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        key='NewPackSnack'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
            <Button
                color="inherit"
                size="small"
                onClick={() => {
                    history.push(`${teamContext.getBaseUrl()}/myteam/packs`)
                    onClose()
                }}>
                SHOW ME
            </Button>
        }
        message={unopenedPacks.length > 1 ? "You have new packs to open!" : "You have a new pack to open!"} />)
}