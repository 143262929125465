import "./MainBodyText.css";
export interface MainBodyTextProps {
  children: any,
}

export const MainBodyTextComponent = (props: MainBodyTextProps) => {
  return (
    <span className='host'>{props.children}</span>
  );
}
