import { ItemProgress, WorldTourChallenge } from '@alienheadwars/football-card-manager-model';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Card, CardHeader, Stack } from "@mui/material";
import React from "react";
import { localiseAndReplace } from '../../../../locale/locale';

export interface WorldTourChallengesCountCardProps {
    challenges: WorldTourChallenge[]
    itemProgress: ItemProgress
}


export const WorldTourChallengesCountCard = (props: WorldTourChallengesCountCardProps) => {
    const challenges = props.challenges.map(challenge => <WorldTourChallengeCard challenge={challenge} itemProgress={props.itemProgress}/>)

    return <Stack spacing={2}>
        {challenges}
    </Stack>
}

const WorldTourChallengeCard = (props: { challenge: WorldTourChallenge, itemProgress: ItemProgress }) => {
    return <Card>
        <CardHeader
            avatar={
                props.itemProgress?.challengesComplete?.find(challengeId => challengeId === props.challenge.id) ? <StarIcon /> : <StarOutlineIcon />
            }
            title={localiseAndReplace({ id: `world-tour.challenge.${props.challenge.type}`, replacements: props.challenge.params })}
        />
    </Card>
}