import "./Title.css";

export interface TitleProps {
  light?: boolean,
  children: any,
}

export const Title = (props: TitleProps) => {
  return (
    <span className={`${props.light ? 'host--light' : 'host--dark'}`}>{props.children}</span>
  );
}
