import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { functions, getCallableFunction } from "../../firebase/firebase";
import { ButtonComponent } from "../base/button/Button";
import { Loading } from "../base/loading/Loading";
import { MainBodyTextComponent } from "../base/mainBodyText/MainBodyText";
import { PageContainerComponent } from "../base/pageContainer/PageContainer";
import { TextInput } from "../base/textInput/TextInput";
import { Title } from "../base/title/Title";
import { httpsCallable } from "firebase/functions";

export const CreateTeamComponent = () => {
    const history = useHistory()
    const [teamName, setTeamName] = useState(``)
    const createTeamFunction = httpsCallable(functions, 'v2CreateStarterTeam')
    const createTeam = async () => {
        const response:any = await createTeamFunction({
            name: teamName
        })
        getTeamFunction({})
        history.push(`/teams/${response.data.teamId}/myteam/packs`)
    }
    const [loading, setLoading] = useState(true)
    const getTeamFunction = getCallableFunction('v1GetTeam')
    useEffect(() => {
        if (loading) {
            getTeamFunction({}).then(response => {
                const existingTeam:any = response.data
                if (existingTeam.teamExists) {
                    history.push(`/teams/${existingTeam.teamId}/myteam`)
                }
                setLoading(false)
            })
        }
    }, [getTeamFunction, history, loading])
    return (
        <>
            <PageContainerComponent>
                {loading ? <Loading /> :
                    <><Title light={false}>FOOTBALL CARD MANAGER</Title>
                        <MainBodyTextComponent>Welcome to Football Card Manager, enter your team name to begin...</MainBodyTextComponent>
                        <TextInput placeholder='Enter your team name' value={teamName} onChange={(text) => setTeamName(text)} />
                        <ButtonComponent onClick={() => createTeam()} loadOnExecute> LET'S GO</ButtonComponent>
                    </>}
            </PageContainerComponent>
        </>
    )
}