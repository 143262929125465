import { Lineup, LookupDetails, Player } from '@alienheadwars/football-card-manager-model';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Card, Grid, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { localise } from '../../../locale/locale';
import { ChoiceButtonsComponent } from '../../base/button/ChoiceButtons';
import { HelpTextComponent } from '../../base/helpText/HelpTextComponent';
import { colours } from '../../base/styles/Colours';
import { MiniCard, MiniPlayerCard } from '../../cards/playerCards/MiniPlayerCard';
import { TeamContext } from "../TeamContext";
import "./SelectPlayer.css";
import { WorldTourContext } from '../play/worldTour/WorldTourContext';

export interface LineupUrlParams {
    teamId: string,
    lineupId: string,
}


export interface SelectPlayerProps {
    lineupId: string
    playerToBeSwapped: number,
}


interface SwapPlayer {
    searchTokens: string[],
    player: Player,
}

export const EditLineup = () => {

    const regionUrlParams = useParams<LineupUrlParams>()

    const teamContext = useContext(TeamContext)
    const worldTourContext = useContext(WorldTourContext)

    const history = useHistory()
    const [newLineup, setNewLineup] = useState<Lineup>(teamContext.getTeamConfig().lineups[regionUrlParams.lineupId])

    const [playerToBeSwapped, setPlayerToBeSwapped] = useState<{ index: number, id: string } | null>(null)

    const [lineupOptions, setLineupOptions] = useState<ReactElement[]>([])
    const [swapOptions, setSwapOptions] = useState<ReactElement[]>([])

    const lineupPlayersMap = useMemo(() => {
        const _lineupPlayersMap: { [key: string]: LookupDetails } = {}
        newLineup.startingLineup.forEach((player: LookupDetails) => {
            _lineupPlayersMap[player.id] = player
        })
        return _lineupPlayersMap
    }, [newLineup])



    const updatePlayer = useMemo(() => {
        return (player: LookupDetails) => {
            const updatedLineup = { ...newLineup, startingLineup: [...newLineup.startingLineup] }
            updatedLineup.startingLineup[playerToBeSwapped!.index] = player
            setNewLineup(updatedLineup)
            setPlayerToBeSwapped({
                index: playerToBeSwapped!.index,
                id: player.id
            })
        }
    }, [newLineup, playerToBeSwapped])

    const removePlayer = useMemo(() => {
        return (player: LookupDetails) => {
            const playerIndex = newLineup.startingLineup.findIndex((lookupDetails: LookupDetails) => lookupDetails.id === player.id)
            const updatedLineup = newLineup
            updatedLineup.startingLineup[playerIndex] = {
                id: 'placeholder',
                collectionId: 'placeholder'
            }
            setNewLineup(updatedLineup)
            setPlayerToBeSwapped({
                index: playerIndex,
                id: 'placeholder',
            })
        }
    }, [newLineup])

    const swapPlayerComponent = useMemo(() => {
        return (player: Player) => {
            const isInLineup = lineupPlayersMap[player.id]
            const primaryAction = isInLineup ? {
                primaryButtonText: "Remove",
                primaryButtonAction: () => {
                    removePlayer(player)
                }
            } : {
                primaryButtonText: "Select",
                primaryButtonAction: () => {
                    updatePlayer(player)
                }
            }

            return <Grid item key={player.id}>
                <MiniPlayerCard
                    player={player}
                    actions={
                        //If already in lineup allow user to remove
                        <ChoiceButtonsComponent
                            {...primaryAction}
                            secondardaryButtonText="Cancel"
                            secondaryButtonAction={() => { }}
                        />}
                    fade={!!isInLineup}
                />
            </Grid>
        }
    }, [updatePlayer, removePlayer, lineupPlayersMap])

    const distinctPlayersMap = useMemo(() => {
        const _distinctPlayersMap: { [key: string]: SwapPlayer } = {}
        teamContext.getTeam().players.forEach((player: Player) => {
            if (!_distinctPlayersMap[player.id]) {
                _distinctPlayersMap[player.id] = {
                    searchTokens: [JSON.stringify(player).toLowerCase()],
                    player: player,
                }
            }
        })
        return _distinctPlayersMap

        //we don't want to update every time playerToBeSwapped is updated
    }, [teamContext])
    const distinctPlayers = useMemo(() => {
        return Object.values(distinctPlayersMap)
            .sort((b, a) => a.player.attack + a.player.defense - b.player.attack - b.player.defense)
    }, [distinctPlayersMap])

    const filterOptions = useMemo(() => {
        const nations = {}
        distinctPlayers.map(player => player.player.nationId!).sort().forEach(nationId => {
            if (!nations[nationId]) {
                nations[nationId] = <Grid item xs={2} onClick={() => {
                    setFilters(existingFilters => { return { ...existingFilters, nationId: nationId } })
                }}>
                    <img
                        src={`/images/flags/192x144/${nationId}.png`}
                        alt={nationId}
                        style={{ width: '100%', }}
                    />
                </Grid>
            }
        }
        )
        const leagues = {}
        distinctPlayers.map(player => player.player.leagueId!).sort().forEach(leagueId => {
            leagues[leagueId] = <Grid item xs={2} onClick={() => {
                setFilters(existingFilters => { return { ...existingFilters, leagueId: leagueId } })
            }}>
                <img
                    src={`/images/badges/${leagueId}.png`}
                    alt={leagueId}
                    style={{
                        // border: `0.1vh solid ${colours[`${props.player.level.toLowerCase()}-border`]}`, 
                        width: '100%'
                    }}
                />
            </Grid>
        }
        )
        return {
            nations: nations,
            leagues: leagues
        }
    }, [distinctPlayers])
    const filterPages = useMemo(() => {
        const recommendedFilters: any[] = []
        if (worldTourContext.getLastLeagueId()) {
            const league = worldTourContext.getCollection().leagues[worldTourContext.getLastLeagueId()]
            recommendedFilters.push(filterOptions.nations[league.nationId!])
            //TODO fix this once league ID fixed
            recommendedFilters.push(filterOptions.leagues[league.id.split('/').pop()!])
        }
        return {
            // intention is that nations/leagues etc. will have a view more functionality, clicking this will change the list of filters to just show those options
            default: <>
                {recommendedFilters.length > 0 ? <><Typography color="text.secondary" gutterBottom>
                    Recommended filters
                </Typography>
                    <Grid container spacing={2}>
                        {recommendedFilters}
                    </Grid></> : ''}
                <Typography color="text.secondary" gutterBottom>
                    Leagues
                </Typography>
                <Grid container spacing={2}>{Object.values(filterOptions.leagues)}</Grid>
                <Typography color="text.secondary" gutterBottom>
                    Nations
                </Typography>
                <Grid container spacing={2}>{Object.values(filterOptions.nations)}</Grid>
            </>
        }
    }, [filterOptions, worldTourContext])
    const [searchText, setSearchText] = useState<string>("")
    const [showFilters, setShowFilters] = useState<string | undefined>(undefined)
    const [filters, setFilters] = useState<{ [key: string]: string }>({})

    const filterPlayer = useMemo(() => {
        setShowFilters(undefined)
        return (player: SwapPlayer) => Object.keys(filters).every(key => player.player[key] === filters[key]) && (!searchText || player.searchTokens.find(token => token.includes(searchText)))
    }, [searchText, filters])

    const [distinctGoalKeepers, distinctOutfieldPlayers] = useMemo(() => {
        const searchedPlayers = distinctPlayers.filter(filterPlayer)
        const _distinctGoalKeepers = searchedPlayers.filter((player: SwapPlayer) => player.player.position === 'GK').map(player => player.player).map(swapPlayerComponent)
        const _distinctOutfieldPlayers = searchedPlayers.filter((player: SwapPlayer) => player.player.position !== 'GK').map(player => player.player).map(swapPlayerComponent)
        return [_distinctGoalKeepers, _distinctOutfieldPlayers]
    }, [distinctPlayers, swapPlayerComponent, filterPlayer])


    //Set lineup players
    useEffect(() => {
        const options = newLineup.startingLineup.map((lookupDetails, index) => {
            if (lookupDetails.id === 'placeholder') {
                return <Grid item key={index}>
                    <MiniCard
                        onClick={() => {
                            setPlayerToBeSwapped({
                                index: index,
                                id: 'placeholder'
                            })
                        }}
                        borderColor={playerToBeSwapped?.index === index ? colours['card-selected'] : 'black'}
                        avatarComponents={<Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><PersonAddIcon fontSize='large' /></Box>}
                    /></Grid >
            }
            const swapPlayer = distinctPlayersMap[lookupDetails.id]
            const player = swapPlayer.player
            const playerIsFiltered = !filterPlayer(swapPlayer)
            return <Grid item key={index}><MiniPlayerCard
                player={player}
                selected={playerToBeSwapped?.index === index}
                fade={playerIsFiltered}
                onClick={playerToBeSwapped?.index === index ? undefined : () => {
                    setPlayerToBeSwapped({
                        index: index,
                        id: lookupDetails.id
                    })
                }}
                actions={
                    <ChoiceButtonsComponent
                        primaryButtonText="Remove"
                        primaryButtonAction={() => {
                            removePlayer(player)
                        }}
                        secondardaryButtonText="Cancel"
                        secondaryButtonAction={() => { }}
                    />}
            /></Grid>
        })
        setLineupOptions(options)
    }, [newLineup, playerToBeSwapped, distinctPlayersMap, removePlayer, filterPlayer])

    //Set swap players
    useEffect(() => {
        if (playerToBeSwapped?.index === 0) {
            setSwapOptions(distinctGoalKeepers)
        }
        else {
            setSwapOptions(distinctOutfieldPlayers)
        }
    }, [playerToBeSwapped, distinctGoalKeepers, distinctOutfieldPlayers, swapPlayerComponent, searchText])


    //God this is an awful work around. I need to find a better way to handle this.
    // It is to prevent when a filter is clicked on, the filter being hidden before the onClick action can occur
    const [leftSearch, setLeftSearch] = useState<boolean>(false)
    useEffect(() => {
        if (leftSearch) {
            setTimeout(() => setShowFilters(undefined), 0)
            setLeftSearch(false)
        }
    }, [leftSearch])

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{ height: '90vh' }}
        >

            {/* This stack will overflow, want it to be scrollable */}
            <Paper sx={{ padding: '1vmin', flexShrink: 0 }}>
                <Typography color="text.secondary" gutterBottom>
                    Starting Lineup
                </Typography>
                <Grid container spacing={1}>
                    {lineupOptions}
                </Grid>
            </Paper>
            {
                // Only show the search bar if a player has been selected
                playerToBeSwapped ? <>
                    <Card sx={{ flexShrink: 0 }}>
                        <TextField id="Search"
                            label={<Box sx={{ display: 'flex', alignItems: 'flex-end' }}><SearchIcon sx={{ color: 'action.active' }} /> Search</ Box>}
                            fullWidth
                            variant="filled"
                            onChange={(event) => setSearchText(event.target.value.toLowerCase())}
                            onFocus={() => setShowFilters('default')}
                            onBlur={() => setLeftSearch(true)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            Object.keys(filters).map(key => {
                                                return <Button variant="contained"
                                                    size="small"
                                                    endIcon={<CloseIcon />}
                                                    onClick={() => setFilters(existingFilters => {
                                                        const newFilters = { ...existingFilters }
                                                        delete newFilters[key]
                                                        return newFilters
                                                    }
                                                    )
                                                    }
                                                    sx={{margin: '0.5vmin'}}
                                                >
                                                    {localise(filters[key])}
                                                </Button>
                                            })
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Card>

                    {showFilters ? <Paper sx={{
                        padding: '1vmin',
                        // flexGrow: 1,
                        flexShrink: 2,
                        flexBasis: 'auto',
                        minHeight: '5vh',
                        overflowY: 'auto'
                    }}>{filterPages[showFilters]}</Paper> :
                        swapOptions.length > 0 ? <Paper sx={{
                            padding: '1vmin',
                            // flexGrow: 1,
                            flexShrink: 2,
                            flexBasis: 'auto',
                            minHeight: '5vh',
                            overflowY: 'auto'
                        }}>
                            <Typography color="text.secondary" gutterBottom>
                                Replacement Players
                            </Typography>
                            <Grid container spacing={1} sx={{ height: '100%' }}>
                                {swapOptions}
                            </Grid>
                        </Paper> :
                            <HelpTextComponent > No players found </HelpTextComponent>}

                </>
                    : <HelpTextComponent > Select a player in the lineup to swap </HelpTextComponent>
            }

            <ChoiceButtonsComponent
                primaryButtonText="Done"
                primaryButtonAction={() => {
                    teamContext.updateTeamConfig(
                        existing => {
                            return {
                                ...existing,
                                lineups: {
                                    ...existing.lineups,
                                    [regionUrlParams.lineupId]: newLineup
                                }

                            }
                        }
                    )
                    history.goBack()
                }}
                //                 primaryButtonDisabled={lineupPlayersMap['placeholder'] ? true : false} doesn't work for some reason ...
                primaryButtonDisabled={newLineup.startingLineup.find(player => player.id === 'placeholder') ? true : false}
                secondardaryButtonText="Cancel"
                secondaryButtonAction={() => { history.goBack() }}
            />
        </Stack>


    )
}