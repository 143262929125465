
import './RoundTotalScore.css'
import { HelpOverlay } from '../../../../base/tutorial/helpOverlay/HelpOverlay'
import React from 'react'
export interface RoundTotalScoreProps {
    totalScore: Number
}

export const RoundTotalScore = (props: RoundTotalScoreProps) => {
    return (
        <HelpOverlay helpText='The combined player scores make the total score' tutorialId='playRoundTotalScore' backgroundColour='white'>
            <span className='round-total-text'>Total Score: <span className='round-total-score'>{props.totalScore}</span></span>
        </HelpOverlay>
    )
}