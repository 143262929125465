import { Player } from '@alienheadwars/football-card-manager-model';
import React from 'react';
import { Overlay } from '../../base/overlay/Overlay';
import { colours } from '../../base/styles/Colours';
import '../CardColours.css';
import './MiniPlayerCard.css';
import { PlayerCardComponent, PlayerCardProps } from "./PlayerCard";
import { Avatar, AvatarComponentProps } from './avatarComponents/Avatar';

export interface MiniPlayerCardProps<PlayerType extends Player> extends PlayerCardProps<PlayerType> {
    fade?: boolean;
    attributes?: AttributeExtraction<PlayerType>[]
    selected?: boolean
}

export interface AttributeExtraction<PlayerType extends Player> {
    (player: PlayerType): any
}

const defaultAttributes: AttributeExtraction<Player>[] = [(player: Player) => {
    return <div className="mini-card-attack-defense-container">
        <div className='mini-card-attribute-attack'>{player.attack}</div>
        <div className='mini-card-attribute-defense'>{player.defense}</div>
    </div>
}]

const ExpandableMiniPlayerCard: <PlayerType extends Player> (props: MiniPlayerCardProps<PlayerType>) => any
    = (props) => {
        // {viewPlayer > -1 ? <Overlay onclick={viewPlayerReset}><PlayerCardComponent player={props.team.lineup[viewPlayer]}></PlayerCardComponent></Overlay> : ''}
        const [viewPlayer, setViewPlayer] = React.useState(false)
        return <>
            {viewPlayer ? <Overlay onclick={() => setViewPlayer(false)}><PlayerCardComponent {...props}></PlayerCardComponent></Overlay> : ''}
            <MiniPlayerCard {...props} onClick={() => setViewPlayer(true)}></MiniPlayerCard>
        </>
    }

export const MiniPlayerCard: <PlayerType extends Player> (props: MiniPlayerCardProps<PlayerType>) => any
    = (props) => {
        if (!props.onClick) {
            return ExpandableMiniPlayerCard(props)
        }
        const avatarComponentsProps: AvatarComponentProps[] = [...props.player.avatar ?? []]
        if (avatarComponentsProps.length === 0) {
            avatarComponentsProps.push({
                type: "image",
                componentName: 'Player 2'
            })
        }
        const avatarComponents = avatarComponentsProps.map(props => {
            return <Avatar {...props} className='mini-card-avatar' />
        })
        const attributes = (props.attributes === undefined ? defaultAttributes : props.attributes).map((attributeExtractor: AttributeExtraction<any>, index: number) => {
            const value = attributeExtractor(props.player)
            return value ?
                <div className={`${props.player.level.toLowerCase()}-border mini-card-attribute-container`}
                    style={{
                        border: `0.4vmin solid ${colours[`${props.player.level.toLowerCase()}-border`]}`,
                    }}>
                    {value}
                </div>
                : ''
        })

        // return (<div className='mini-card-container' onClick={props.onClick}>
        //     <div className='mini-card'
        //         style={{
        //             backgroundColor: colours[`${props.player.level.toLowerCase()}-background`],
        //             border: `0.4vmin solid ${colours[`${props.selected ? 'card-selected' : props.player.level.toLowerCase()}-border`]}`,
        //         }} />
        //     <div className='mini-card-avatar-container'>
        //         {avatarComponents}
        //     </div>
        //     {attributes}


        // </div>
        // )

        return <MiniCard
            avatarComponents={avatarComponents}
            attributes={attributes}
            backgroundColor={colours[`${props.player.level.toLowerCase()}-background`]}
            borderColor={colours[`${props.selected ? 'card-selected' : props.player.level.toLowerCase()}-border`]}
            onClick={props.onClick}
            fade= {props.fade}
        />
    }


export interface MiniCardProps {
    backgroundColor?: string
    borderColor?: string
    onClick: () => void
    avatarComponents: any
    attributes?: any
    fade?:boolean
}
export const MiniCard = (props: MiniCardProps) => {
    return <div className='mini-card-container' onClick={props.onClick} style={props.fade?{opacity:0.4}:{}}>
        <div className='mini-card'
            style={{
                backgroundColor: props.backgroundColor??'white',
                border: `0.4vmin solid ${props.borderColor??'black'}`,
            }} />
        <div className='mini-card-avatar-container'>
            {props.avatarComponents}
        </div>
        {props.attributes}
    </div>
}