import { WorldTourTeam } from '@alienheadwars/football-card-manager-model/dist/world-tour/worldTourTeam';
import { Button, CardActions, CardMedia, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { TeamContext } from "../../TeamContext";
import { WorldTourCard } from './WorldTourCard';
import { WorldTourChallengesCountCard } from './WorldTourChallenges';
import { WorldTourContext } from './WorldTourContext';



export const WorldTourTeamCard = (team: WorldTourTeam) => {

    const teamContext = useContext(TeamContext)
    const worldTourContext = useContext(WorldTourContext)
    const worldTourProgress = worldTourContext.getWorldTourProgress()
    const itemProgress = worldTourProgress.teams[team.id]
    const history = useHistory()
    return <WorldTourCard
        header={<Typography variant="h5" component="div" sx={{ color: team.colours?.primaryTextColour ?? 'black' }}>{team.name}</Typography>}
        subHeader={''}
        disabled={!itemProgress?.unlocked}
        avatar={<CardMedia
            component="img"
            sx={{ width: 60, border: 2, borderColor: team.colours?.primaryColour ?? 'black', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
            image={`/images/badges/${team.id}.png`}
            alt={team.id}
        />}
        contentId='teams'
        id={team.id}
        key={team.id}
        defaultExpanded={itemProgress?.unlocked && itemProgress.challengesComplete.length < team.challenges.length}
        sx={{
            backgroundColor: team.colours?.primaryBackgroundColour,
            color: team.colours?.primaryTextColour ?? 'black'
        }}
    >
        <WorldTourChallengesCountCard challenges={team.challenges} itemProgress={itemProgress} />
        <CardActions>
            <Button
                size="small"
                onClick={() => {
                    history.push(`${teamContext.getBaseUrl()}/play/world-tour/${team.id}`)
                    worldTourContext.setLastOpponentId(team.id) 
                }}>

                Play now</Button>
        </CardActions>
    </WorldTourCard>
}

