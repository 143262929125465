import { Collection, CollectionManager, SimpleCollectionManager } from "@alienheadwars/football-card-manager-model/dist/collection/collection"
import { getDocument } from "../firebase/firestore"


//TODO this class should really be a context component


const fetchCollection: (collectionId: string) => Promise<Collection> = async (collectionId: string) => {
    return (await getDocument('collections/'+collectionId) )  as Collection
}



export const collectionManager:CollectionManager = new SimpleCollectionManager(fetchCollection)