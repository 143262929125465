
import type { } from '@mui/lab/themeAugmentation';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import React from 'react';

const theme = createTheme({
    palette: {
        primary: {
            main: '#883CA3',
        },
        secondary: {
            main: '#fff',
            contrastText: '#883CA3',
        },
    },
    components: {
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === 'contained' &&
                ownerState.color === 'secondary' && {
                  border: 'thin solid #883CA3'
                }),
            }),
          },
        },
      },
});
export interface ThemeProps {
    children: any
}


export const ThemeComponent = (props: ThemeProps) => {
    return <ThemeProvider theme={theme}>
        {props.children}
    </ThemeProvider>
}