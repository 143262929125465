import { Stack } from "@mui/material";
import React, { useContext } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { ButtonComponent } from "../../../base/button/Button";
import { WorldTourContext } from "./WorldTourContext";
import { WorldTourLeagueCard } from "./WorldTourLeagueCard";
import { WorldTourLeagueHome } from "./WorldTourLeagueHome";

export interface RegionUrlParams {
    teamId: string,
    regionId: string,
}

export const WorldTourRegionHome = () => {

    const regionUrlParams = useParams<RegionUrlParams>()
    const worldTourContext = useContext(WorldTourContext)
    const history = useHistory()

    const leagues = worldTourContext.getCollection().regions[regionUrlParams.regionId].leagues.map(league => {
        return <WorldTourLeagueCard {...league} />
    })


    return <Switch>
        <Route exact path="/teams/:teamId/play/world-tour/:regionId">
            <Stack spacing={2}>
                {leagues}
                <ButtonComponent onClick={() => {
                    history.push(`/teams/${regionUrlParams.teamId}/play/world-tour/`)
                    worldTourContext.setLastRegionId(undefined)
                }
                }>Back to region select</ButtonComponent>
            </Stack>
        </Route>
        <Route path="/teams/:teamId/play/world-tour/:regionId/:leagueId" component={WorldTourLeagueHome} />
    </Switch>



}

